import Button from "components/Button";
import Form from "components/Form";
import TextInput from "components/TextInput";
import useAuth from "hooks/useAuth";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import NavBar from "../components/NavBar";
import SignUpGoogle from "../components/SignUpGoogle";
import { ReactComponent as Arrow } from "assets/img/icons/Arrow 2.svg";
import Spinner from "components/Spinner";
import { toast } from "react-toastify";

export default function SignIn() {
	const navigate = useNavigate();
	const [user, setUser] = useState({
		email: "",
		password: "",
	});

	const { logIn } = useAuth();
	const [showPassword, setShowPassword] = useState(false);

	const [loading,setLoading] = useState(false)

	const handleShowPass = () => {
		setShowPassword(!showPassword);
	};

	const handleChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setUser({ ...user, [e.target.name]: e.target.value });
	};

	const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setLoading(true)
		const result = await logIn(user.email, user.password);
		if (result.isRigth) {
			setLoading(false)	
			navigate("/dashboard");
		}
		else{
			setLoading(false)	
			toast.error(result.getError());
		}
	};

	return (
		<div className="flex flex-col items-center w-full">
			<NavBar
				primaryText="Don't have an account?"
				secondaryText="Sign Up"
				link="/sign-up"
			/>
			<div className="flex flex-col items-center w-[70%] md:w-[50%] lg:w-[35%]">
				<h2 className="text-4xl font-semibold text-center mt-6">
					Start your free trial
				</h2>
				<Form className="pt-6 mb-4 w-full" onSubmit={onSubmit}>
					<TextInput
						className=" mt-6 mb-8"
						value={user.email}
						onChange={handleChange}
						placeholder="E-mail"
						name="email"
						type="email"
						validate={(value) => {
							if (value === "") {
								return "Required field";
							}
							return "";
						}}
					/>
					<TextInput
						className="mb-3"
						value={user.password}
						onChange={handleChange}
						placeholder="Password"
						name="password"
						type={showPassword ? "text" : "password"}
						validate={(value) => {
							if (value === "") {
								return "Required field";
							}
							return "";
						}}
					/>
					{/* <Input label="E-mail" linkedName="email" inputType="email" />
					<Input label="Password" linkedName="password" inputType="password" /> */}

					<div className="flex justify-between">
						<Link to="/forgot-password"  className="text-xs text-blue mb-2">Forgot Password?</Link>
						<button
							type="button"
							onClick={handleShowPass}
							className="text-xs text-blue mb-2"
						>
							Show Password
						</button>
					</div>

					<div className="flex items-center mb-4">
						<input
							id="rememberMe-checkbox"
							type="checkbox"
							value=""
							className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
						/>
						<label
							htmlFor="rememberMe-checkbox"
							className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
						>
							Remember me
						</label>
					</div>
					
					<Button type="submit" background={"bg-disabled"} textColor={"text-text-secondary"} height="h-11">{loading ? <Spinner/>: <><span>Log in</span> <Arrow className="ml-2 h-3" /></>}</Button>
					<div className="border-t h-1 w-full mt-6"></div>
					<SignUpGoogle />
				</Form>
			</div>
		</div>
	);
}
