import User from "models/User";
import { createContext, ReactNode, useContext, useReducer } from "react";

type Action = {
	type: string;
	payload: any;
};

type UserState = {
	isAuthenticated: boolean;
	user: User | null;
};

var initialValues = {
	// isAuthenticated: !!localStorage.getItem("id_token"),
	user: null,
	isAuthenticated: false,
} as UserState;

var UserStateContext = createContext(initialValues);
var UserDispatchContext = createContext<React.Dispatch<any>>(() => null);

function userReducer(state: UserState, action: Action) {
	switch (action.type) {
		case "DONT_LOGGED":
			return {
				...state,
				user: null,
				isAuthenticated: false,
			} as UserState;
		case "SET_USER":
			return {
				...state,
				user: action.payload,
				isAuthenticated: true,
			} as UserState;
		default: {
			throw new Error(`Unhandled action type: ${action.type}`);
		}
	}
}

function UserProvider({ children }: { children: ReactNode }) {
	var [state, dispatch] = useReducer(userReducer, initialValues);
	return (
		<UserStateContext.Provider value={state}>
			<UserDispatchContext.Provider value={dispatch}>
				{children}
			</UserDispatchContext.Provider>
		</UserStateContext.Provider>
	);
}

function useUserState() {
	var context = useContext(UserStateContext);
	if (context === undefined) {
		throw new Error("useUserState must be used within a UserProvider");
	}
	return context;
}

function useUserDispatch() {
	var context = useContext(UserDispatchContext);
	if (context === undefined) {
		throw new Error("useUserDispatch must be used within a UserProvider");
	}
	return context;
}

export {
	UserProvider,
	useUserState,
	useUserDispatch,
	setUser,
	// loginAction,
	// registerAction,
	// signOutAction,
	// getUserAction,
};

// ###########################################################

async function setUser(dispatch: any, user: User) {
	dispatch({ type: "SET_USER", payload: user });
}
