import { useUserState } from "contexts/user_context";
import { Navigate } from "react-router-dom";

type RouteProps = { Component: any };

export function PublicRoute({ Component }: RouteProps) {
	const { isAuthenticated } = useUserState();

	return isAuthenticated ? <Navigate to="/dashboard" replace /> : <Component />;
}

export function PrivateRoute({ Component }: RouteProps) {
	const { isAuthenticated } = useUserState();

	return isAuthenticated ? <Component /> : <Navigate to="/" replace />;
}
