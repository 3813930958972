import LogoBlack from "assets/img/logo_black.png";
import { Link } from "react-router-dom";

function NavBar({primaryText,link,secondaryText}:{primaryText: string; link:string ; secondaryText:string}) {
	return (
		<nav className="flex flex-col items-center lg:flex-row pt-8  w-full">
			<div className="lg:ml-20">
			<Link to='/'><img src={LogoBlack} className="w-36" alt="logo" /></Link>	
			</div>

			<div className="flex flex-row mt-2 lg:ml-auto lg:mr-20">
				<p className="">
					{primaryText} <Link to={link} className="text-blue">{secondaryText}</Link>
				</p>
			</div>
		</nav>
	);
}

export default NavBar;
