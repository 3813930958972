import AuthServices from "services/auth_services";
import OpenAIServices from "services/openai_services";
import OutputServices from "services/output_services";
import ProfileServices from "services/profile_services";
import { container, InjectionToken } from "tsyringe";
import Firebase from "utils/firebase";

export function sl<T>(dependency: InjectionToken<T>): T {
	return container.resolve<T>(dependency);
}

export function registerDependencies() {
	container.register<Firebase>("Firebase", {
		useValue: new Firebase(),
	});

	container.register<AuthServices>("AuthServices", {
		useValue: new AuthServices(sl<Firebase>("Firebase")),
	});

	container.register<ProfileServices>("ProfileServices", {
		useValue: new ProfileServices(sl<Firebase>("Firebase")),
	});

	container.register<OpenAIServices>("OpenAIServices", {
		useValue: new OpenAIServices(),
	});

	container.register<OutputServices>("OutputServices", {
		useValue: new OutputServices(sl<Firebase>("Firebase")),
	});
}
