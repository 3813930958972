import React from 'react'
import Logo from "assets/img/logo.svg";
export default function Footer() {
  return (
    <div className="flex flex-col bg-brown w-full h-fit mt-20 pb-2 mb-4">
        <div className="flex flex-col lg:flex-row justify-between pl-4 lg:pl-8 pr-3 pt-4">
          <div className="flex flex-col w-[60%] lg:w-[18%] mb-[10%] lg:mb-0">
            <img className="h-6 lg:h-[10%] mb-2 self-start" src={Logo} alt="" />
            <p className="text-white text-sm mb-2 font-roboto">
              Amzhub is the world’s leading software for generating Amazon product copy.
            </p>
          </div>
          <div className="flex flex-col w-[60%] lg:w-[18%] mb-4 lg:mb-0">
            <h2 className="text-white font-bold pb-2 font-roboto mb-2 lg:mb-0">Products</h2>
            <p className="text-white text-sm pb-3 font-roboto opacity-[0.7]">
              Product Description Generator
            </p>
            <p className="text-white text-sm pb-3 font-roboto opacity-[0.7]">Improve Existing Product Copy</p>
            <p className="text-white text-sm pb-3 font-roboto opacity-[0.7]">
            Product Reviews Generator
            </p>
            <p className="text-white text-sm pb-3 font-roboto opacity-[0.7]">Keywords Generator</p>
          </div>
          <div className="flex flex-col w-[60%] lg:w-[18%] mb-4 lg:mb-0">
            <h2 className="text-white font-bold pb-2 font-roboto">
              Social Media
            </h2>
            <p className="text-white text-sm pb-3 font-roboto opacity-[0.7]">Facebook</p>
            <p className="text-white text-sm pb-3 font-roboto opacity-[0.7]">Instagram</p>
            <p className="text-white text-sm pb-3 font-roboto opacity-[0.7]">Youtube</p>
          </div>
          <div className="flex flex-col w-[60%] lg:w-[18%] mb-4 lg:mb-0">
            <h2 className="text-white font-bold pb-2 font-roboto">Company</h2>
            <p className="text-white text-sm pb-3 font-roboto opacity-[0.7]">About us</p>
          </div>
          <div className="flex flex-col  w-[60%] lg:w-[18%] mb-4 lg:mb-0">
            <h2 className="text-white font-bold pb-2 font-roboto">
              Help Center
            </h2>
            <p className="text-white text-sm pb-1 font-roboto opacity-[0.7]">
              Contact Support
            </p>
          </div>
        </div>
        <div className="border-t border-gray-600 w-[95%] mt-8 mb-1 mx-auto"></div>
        <div className="flex justify-between">
          <h2 className="text-white ml-8 font-roboto text-sm hidden lg:flex">
            Contact Us: hello@amzhub.io
          </h2>
          <div className="flex w-full justify-between px-2 lg:px-8 lg:justify-start lg:w-fit">
            <h2 className="text-white  lg:mr-8 font-roboto lg:text-sm text-[10px]">
              Privacy Policy
            </h2>
            <h2 className="text-white  lg:mr-8 font-roboto lg:text-sm text-[10px]">
              Terms of Use
            </h2>
            <h2 className="text-white  lg:mr-8 font-roboto lg:text-sm text-[10px]">
            Copyright © 2022 Amzhub, All Rights Reserved.
            </h2>
          </div>
        </div>
      </div>
  )
}
