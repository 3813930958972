import React, { ReactElement } from 'react'
import Button from 'components/Button'

import { ReactComponent as Arrow } from "assets/img/icons/DoubleArrow.svg";
import { useNavigate } from 'react-router-dom';

export default function Header({title,subtitle,icon}:{title:string;subtitle:string;icon?:string}) {
    const navigate = useNavigate();
  return (
    <div className=" lg:flex mt-8 lg:ml-8 lg:items-center">
        <div className="lg:flex-row flex-col flex justify-center lg:items-center">
          <img className="h-16" src={icon} alt="" />
          <div className="flex flex-col px-2 lg:px-0 items-center lg:items-start lg:ml-4">
            <h4 className="text-xl text-center lg:text-start font-inter font-semibold">
              {title}
            </h4>
            <p className="text-center lg:text-start font-inter text-sm text-gray-600">
              {subtitle}
            </p>
          </div>
        </div>
        <div className="hidden lg:flex ml-auto mr-12">
          <Button
            background="bg-[#d9d9d9]"
            textColor="text-gray-600"
            height="h-10"
            onClick={()=>{navigate("/templates")}}
          >
            <Arrow className="h-9 ml-2 mr-4" />
            <span className="mr-4 text-gray-600">Templates</span>
          </Button>
        </div>
      </div>
  )
}
