import { Menu, Transition} from '@headlessui/react'
import { Fragment, ReactElement, useEffect, useRef, useState } from 'react'
import {ReactComponent as Settings} from "assets/img/icons/Settings1.svg"
import {ReactComponent as Billing} from "assets/img/icons/Billing.svg"
import {ReactComponent as Usage} from "assets/img/icons/Usage.svg"
import {ReactComponent as UserAdd} from "assets/img/icons/UserAdd.svg"
import {ReactComponent as SignOut} from "assets/img/icons/SignOut.svg"
import { Link, useNavigate } from 'react-router-dom'
import useAuth from 'hooks/useAuth'



export default function DropdownMenu({children}:{children:ReactElement}) {
  const navigate = useNavigate();
	const { logOut } = useAuth();

	const signOut = async (e: React.MouseEvent<HTMLButtonElement>) => {
		const result = await logOut();
		if (result.isRigth) {
			navigate("/");
			window.location.replace("");
		}
	};

  return (
    <div className="flex top-16 w-56 text-right justify-center">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="flex w-full justify-center items-center rounded-md bg-white bg-opacity-20 px-4 py-2 text-sm font-medium text-black hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-black focus-visible:ring-opacity-75">
            {children}
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 -translate-y-[120%] translate-x-[30%]  w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1 ">
              <Menu.Item>
                {({ active }) => (
                 
                    <Link to="/settings/personal"  className={`${
                      active ? 'bg-violet-500 text-white' : 'text-gray-900'
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}>
                      <Settings
                        className="mr-2 h-5 w-5"
                        aria-hidden="true"
                      />
                    Account settings
                    </Link>
                  
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                    <Link to="/settings/billing"  className={`${
                      active ? 'bg-violet-500 text-white' : 'text-gray-900'
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}>
                      <Billing
                        className="mr-2 h-5 w-5"
                        aria-hidden="true"
                      />
                    Billing
                    </Link>
                )}
              </Menu.Item>
            </div>
            <div className="px-1 py-1">
              <Menu.Item>
                {({ active }) => (
                  
                    <Link to="/settings/billing" className={`${
                      active ? 'bg-violet-500 text-white' : 'text-gray-900'
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}>
                      <Usage
                        className="mr-2 h-5 w-5"
                        aria-hidden="true"
                      />
                    Usage
                    </Link>
      
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  
                    <Link to="/settings/personal"  className={`${
                      active ? 'bg-violet-500 text-white' : 'text-gray-900'
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}>
                      <UserAdd
                        className="mr-2 h-5 w-5"
                        aria-hidden="true"
                       
                      />
                    Add members
                    </Link>
                 
                )}
              </Menu.Item>
            </div>
            <hr className="border-black bg-black w-full"/>
            <div className="px-1 py-1 mt-2">
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-violet-500 text-white' : 'text-gray-900'
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    onClick={signOut}
                  >
                      <SignOut
                        className="mr-2 h-5 w-5 text-violet-400"
                        aria-hidden="true"
                      />
                    Sign out
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}

