import NavBar from "./components/NavBar";
import MainGraph from "assets/img/mainGraph.png";
import FivestarProductQuality from "assets/img/icons/5-star product quality.svg";
import Creativity from "assets/img/icons/Creativity 1.svg";
import productAnalytic from "assets/img/icons/product analytic.svg";
import IAPower from "assets/img/IA Power.png";
import Arrow from "assets/img/icons/Arrow 1.svg";
import Profit from "assets/img/icons/Profit 1.svg";
import { ReactComponent as Star } from "assets/img/icons/Star.svg";
import TimeIsMoney from "assets/img/icons/Time is money 1.svg";
import WorldwideProductDelivery from "assets/img/icons/worldwide product delivery 1.svg";
import UserImg1 from "assets/img/User 1.png";
import UserImg2 from "assets/img/User 2.png";
import { Link } from "react-router-dom";
import Footer from "./components/Footer";
import Questions from "./components/Questions";
// import OrangeButton from "../../components/OrangeButton";

function OrangeButton() {
  return (
    <button
      type="button"
      className="bg-secondary py-3 px-8 rounded-xl text-sm font-roboto font-semibold"
    >
      <Link className="font-roboto" to="/sign-up">
        Start Free Trial
      </Link>
    </button>
  );
}

function Home() {
  return (
    <div className="bg-primary h-full w-full overflow-x-hidden">
      <NavBar />

      {/* First Section */}
      <section className="h-fit lg:h-screen w-full mb-12">
        <div className="hidden lg:block lg:absolute h-screen">
          <img
            className="mx-auto w-[95%] -z-10"
            src={MainGraph}
            alt="mainGraph"
          />
        </div>
        <div className="h-full w-full flex flex-col lg:grid lg:grid-cols-2 lg:z-10  mb-12">
          <div className="flex flex-col lg:items-start items-center pt-20 lg:py-24 px-4 lg:px-24">
            <h1 className="text-text-primary text-6xl text-center lg:text-start mb-6 font-poppins ">
              Let Ai. write your <span className="text-secondary">Amazon</span>{" "}
              products copy.
            </h1>

            <p className="text-text-primary text-xl text-center lg:text-start mb-6 font-roboto">
              Artificial intelligence trained to write high-converting product
              copy in seconds.
            </p>

            <OrangeButton />
          </div>

          <div className="flex my-14 lg:mt-20 px-6 lg:px-[15%]">
            <p className="bg-text-secondary bg-opacity-20 backdrop-blur-lg rounded drop-shadow-lg text-text-primary px-12 py-8 h-fit font-poppins">
              - EASY TO WEAR - The VIFUUR water sports shoes are extremely easy
              to wear. Simply slip them on and you're ready to go! <br />
              <br />
              - CONVENIENT FOR SWIMMING - Our shoes have a specially designed
              sole that makes them perfect for swimming. You'll love how easy
              they are to move in and how comfortable they are. <br />
              <br />- KEEP YOUR FEET PROTECTED - Our shoes provide protection
              against sharp objects and hot surfaces, so you can feel safe when
              walking on the beach or around the pool.
            </p>
          </div>
        </div>
      </section>

      {/* Second Section */}
      <section className="w-full h-fit lg:h-screen">
        <div className="z-30 bg-gradient-to-r from-secondary to-white absolute h-7 w-[12%] lg:w-32 mt-10"></div>
        <div className="bg-brown ml-auto w-11/12">
          <div className="flex flex-col items-center px-8 lg:px-0 lg:grid lg:grid-cols-2 pt-20 lg:pt-16 ">
            <h2 className="text-text-primary font-roboto text-3xl lg:text-2xl md:pl-10 lg:pl-32 lg:pr-8 text-center lg:text-start font-semibold">
              Producing a well-written copy will always be a key factor in your
              sales.
            </h2>
            <p className="text-text-primary font-roboto text-center lg:text-start px-4 mt-6 lg:pl-16 md:pr-8 lg:pr-28 text-lg lg:text-lg ">
              When you have a milling things to do, is hard to find time to
              write unique product copy. Our AI generates perfect product copy
              and leaves room for your personal touch.
            </p>
          </div>

          <div className="flex flex-col items-center lg:grid lg:grid-cols-3 lg:justify-between lg:items-center pt-20">
            <div className="flex flex-col items-center lg:items-start px-6  md:px-8 lg:px-[10%] mb-14 h-60">
              <img
                className="h-32 lg:h-20"
                src={FivestarProductQuality}
                alt="" 
              />
              <h3 className="mt-4 text-text-primary text-center text-xl px-12 lg:px-0  lg:text-start font-roboto font-semibold">
                Create High-Converting Product Descriptions
              </h3>
              <p className="mt-4 text-text-primary px-8 lg:px-0 text-center lg:text-start font-roboto">
                Our AI is trained to create compelling, informative and unique
                product descriptions in just a few clicks.
              </p>
            </div>
            <div className="flex flex-col items-center lg:items-start px-6 md:px-8 lg:px-[10%] mb-14 h-60">
              <img className="h-32 lg:h-20" src={Creativity} alt="" />
              <h3 className="mt-4 text-text-primary text-center text-xl px-12 lg:px-0  lg:text-start font-roboto font-semibold">
                Genarate Exceptional Product Names
              </h3>
              <p className="mt-4 text-text-primary px-8 lg:px-0 text-center lg:text-start font-roboto">
                Artificial intelligence trained to write high-converting product
                descriptions in seconds.{" "}
              </p>
            </div>
            <div className="flex flex-col items-center lg:items-start px-6 md:px-8 lg:px-[10%] mb-14 h-60">
              <img className="h-32 lg:h-20" src={productAnalytic} alt="" />
              <h3 className="mt-4 text-text-primary text-center text-xl px-12 lg:px-0  lg:text-start font-roboto font-semibold">
                Extract Key-Words from Product Descriptions{" "}
              </h3>
              <p className="mt-4 text-text-primary  px-8 lg:px-0 text-center lg:text-start font-roboto">
                Artificial intelligence trained to write high-converting product
                descriptions in seconds.{" "}
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Third Section */}

      <section className="flex flex-col items-center lg:grid lg:grid-cols-2 py-32 w-full h-fit lg:h-screen">
        <img className="h-72 mx-auto " src={IAPower} alt="" />
        <div className="flex flex-col items-center mt-8  lg:mt-0 lg:px-0 lg:items-start md:pr-4 lg:pr-20">
          <h2 className="text-text-primary text-center lg:text-start text-4xl lg:text-5xl font-roboto font-semibold">
            Skyrocket your store’s performance using advanced AI power
          </h2>
          <p className="pt-10 px-6 lg:px-0 lg:pt-5 lg:pr-24 text-center lg:text-start text-text-primary font-roboto">
            Our artificial intelligence is trained to write human-level copy in
            half the time, at a very affordable price. Join the hundreds of
            Amazon stores already taking advantage of this technology.
          </p>
          <div className="pt-4 flex items-center">
            <Link
              to="/sign-in"
              className="text-secondary mr-2 text-xl font-roboto"
            >
              7-day free trial
            </Link>
            <img className="h-3" src={Arrow} alt="" />
          </div>
        </div>
      </section>

      {/* Forth Section */}
      <section className="flex flex-col lg:grid lg:grid-cols-3 bg-secondary mt-2 lg:mt-44 w-full h-full">
        <div className="flex flex-col items-center py-12">
          <img className="w-[30%]" src={Profit} alt="" />
          <p className="px-28 text-center font-bold font-roboto text-xl">
            7k+ product descriptions generated
          </p>
        </div>
        <div className="flex flex-col items-center py-12">
          <img className="w-[30%]" src={TimeIsMoney} alt="" />
          <p className="px-28 text-center font-bold font-roboto text-xl">
            7500+ writing hours saved
          </p>
        </div>
        <div className="flex flex-col items-center py-12">
          <img className="w-[30%]" src={WorldwideProductDelivery} alt="" />
          <p className="px-28 text-center font-bold font-roboto text-xl">
            Used by 150+ stores worldwide
          </p>
        </div>
      </section>

      {/* Fifth Section */}
      <section className="flex flex-col items-center w-full h-full mt-32 lg:mt-16">
        <div className="flex lg:flex-row flex-col items-center w-[95%]  lg:h-screen h-fit">
          <div className="w-[95%] lg:w-[50%] flex justify-center -mt-[10%]">
            <h2 className="text-white text-[40px] leading-[1.3] text-center  mb-6 font-roboto w-full lg:w-[60%] lg:leading-[1.4] font-semibold">
              Our users save an average of <span className="text-secondary">35 hours</span> each month!
            </h2>
          </div>

          <div className="flex flex-col items-center pt-20 w-full lg:w-[50%] h-full">
            <div className="flex items-center justify-center w-full mb-16">
              <div className="flex- flex-col w-[80%] mr-2">
                <div className="flex w-[35%] space-x-1 ml-auto mb-1 mr-3">
                  <Star />
                  <Star />
                  <Star />
                  <Star />
                  <Star />
                </div>
                <div className="bg-brown rounded-3xl w-full lg:w-128 h-fit mr-4 flex items-center text-white font-roboto justify-center px-3 lg:px-9 py-4 text-xs lg:text-base ">
                  “This website is super cool! You just input what product you
                  want to describe and it uses AI to generate a description for
                  you. I found it really helpful when I was writing product
                  descriptions for my Amazon business. The results were really
                  accurate and it saved me a lot of time. Highly recommend this
                  tool!”
                </div>
              </div>

              <img
                className="mt-[5%] w-[25%]  md:w-[25%] lg:w-[20%]  border-secondary border-2 rounded-full"
                src={UserImg1}
                alt=""
              />
            </div>
            <div className="flex items-center justify-center w-full mb-16">
              <div className="flex items-center justify-center w-full">
                <img
                  className="mt-[5%] w-[25%] md:w-[25%] lg:w-[20%]  border-secondary border-2 rounded-full "
                  src={UserImg2}
                  alt=""
                />
                <div className="flex flex-col w-[80%] ml-2">
                <div className="flex w-[35%] space-x-1 ml-3 mb-1">
                  <Star />
                  <Star />
                  <Star />
                  <Star />
                  <Star />
                </div>
                <div className="bg-brown rounded-3xl w-full lg:w-128 h-fit mr-4 flex items-center text-white font-roboto justify-center px-3 lg:px-9 py-4 text-xs lg:text-base">
                  If you're looking for a website that can help you generate
                  Amazon product names using AI, Amzhub is a great option. The
                  site is easy to use and provides great results.
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Youtube Video */}
        <div className="h-full w-full flex flex-col">
          <h2 className="ml-4 lg:ml-[15%] text-white text-xl font-roboto font-semibold mb-3">
            Overview.
          </h2>
          <div className="w-[90%] lg:w-[70%] h-64 lg:h-[60vh] bg-brown mx-auto flex justify-center items-center">
            Youtube Video
          </div>
        </div>

        <div className="flex flex-col items-center mt-32 mb-16">
          <h2 className="px-1 lg:px-0 text-text-primary text-5xl text-center lg:text-start font-semibold">
            Start Growing With <span className="text-secondary">amzhub</span>{" "}
            Today
          </h2>
          <p className="text-text-primary text-center lg:text-start my-5 font-semibold">
            7-day free trial. No credit card requierd.
          </p>
          <OrangeButton />
        </div>
      </section>

      {/* Questions */}
      <Questions />

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default Home;
