import OutputServices from "services/output_services";
import { useInjection } from "utils/dependency_injection";

export default function useOutput() {
	const outputServices = useInjection<OutputServices>("OutputServices");

	const addOutputToDB = async ({content,date,favorite,templateType,uid,inputs}:{content: string,date: Date,favorite: boolean,templateType: string,uid: string,inputs:{}}) => {
        return await outputServices.addOutputToDB(content,date,favorite,templateType,uid,inputs);
    }
	const getOutputFromDB = async ({uid}:{uid:string}) => {
        return await outputServices.getOutputFromDB(uid)
    }

    const changeFavorites = async ({uid,docId,data}:{uid:string,docId:string,data:any}) =>{
        return await outputServices.setFavorites(uid,data,docId)
    } 
    const getFavorites = async ({uid}:{uid:string}) => {
        
        return await outputServices.getFavorites(uid)
    } 
    const getHistorialByType = async ({uid,templateType}:{uid:string;templateType: string}) => {
        
        return await outputServices.getHistorialByType(uid,templateType)
    } 

	return { addOutputToDB, getOutputFromDB,changeFavorites,getFavorites,getHistorialByType };
}
