import React from 'react'
import Button from "components/Button";
import { ClearIcon, ArrowIcon } from 'utils/iconsSVG';

export default function ButtonPanel({numberOutputs,onChange,showOutputController}:{numberOutputs?:number,onChange?:any,showOutputController?:boolean}) {
  return (
    <div className="relative lg:absolute flex space-x-2 mt-4 lg:mt-0 justify-between items-center w-full bottom-0 left-0 right-0  py-2 px-2 lg:border-r lg:border-l lg:border-[#d9d9d9]">
              <div className="w-[35%] lg:w-[25%] py-1">
                <Button
                  background="bg-[#d9d9d9]"
                  textColor="text-disabled"
                  type="reset"
                  height="h-7"
                >
                  <ClearIcon className="h-4 lg:ml-2 lg:mr-2 mr-[2px] text-white" fill={"#222"} />
                  <span className="lg:mr-4 text-gray-600 font-inter text-xs">Clear inputs</span>
                </Button>
              </div>
              {showOutputController ?  
              <div className="flex justify-center items-center py-2 rounded text-sm font-roboto font-semibold w-[30%] lg:w-[25%] h-7 bg-[#d9d9d9] text-disabled">
                <input
                  className="w-6 lg:w-8 bg-transparent focus:outline-none ml-5 lg:mr-2 text-gray-600 font-inter"
                  type="number"
                  value={numberOutputs}
                  onChange={onChange}
                />
                <span className="mr-4 text-gray-600 font-inter">Outputs</span>
              </div> : null }
             
              <div className="w-[30%] lg:w-[25%] py-1">
                <Button
                  type="submit"
                  background="bg-secondary"
                  textColor="text-disabled"
                  height="h-7"
                >
                  <span className="mr-1 text-white lg:ml-4 font-inter">Generate</span>
                  <ArrowIcon className="h-3" fill='#FFF'/>
                </Button>
              </div>
            </div>
  )
}
