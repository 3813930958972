import { NextOrObserver, User } from "firebase/auth";
import { Result } from "models/core/Result";
import Firebase from "utils/firebase";
import UserModel from "models/User";

export default class AuthServices {
	private firebase: Firebase;

	constructor(firebase: Firebase) {
		this.firebase = firebase;
	}

	login = async (
		email: string,
		password: string
	): Promise<Result<UserModel>> => {
		try {
			const user = await this.firebase.signInWithEmailAndPassword(
				email,
				password
			);
			const data = await this.firebase.getDocInCollection("users", user.uid);
			return Result.rigth<UserModel>(
				new UserModel({
					email: data?.email,
					firstName: data?.firstName,
					lastName: data?.lastName,
					id: user.uid,
				})
			);
		} catch (error) {
			return Result.left("Ocurrio un error");
		}
	};

	loginWithGoogle	= async (): Promise<Result<UserModel>> => {
		try{
			const user = await this.firebase.signInWithGoogle();

			const data = await this.firebase.getDocInCollection("users", user.uid);
			

			if(data === undefined) {
				await this.firebase.setDocInCollection("users", user.uid, {
					firstName: user.displayName?.split(" ")[0],
					lastName: user.displayName?.split(" ")[1],
					email: user.email,
				});
			const data = await this.firebase.getDocInCollection("users", user.uid);

			return Result.rigth<UserModel>(
				new UserModel({
				email: data?.email,
				firstName: data?.firstName,
				lastName: data?.lastName,
				id: user.uid,
			}))
			}
			
			return Result.rigth<UserModel>(
				new UserModel({
				email: data?.email,
				firstName: data?.firstName,
				lastName: data?.lastName,
				id: user.uid,
			}))
		}
		catch(error){
			return Result.left("Ocurrio un error")
		}
	}

	logOut = async () => {
		try {
			await this.firebase.logOut();
			return Result.rigth();
		} catch (error) {
			return Result.left("Ocurrio un error");
		}
	};

	ForgotPasswordResetEmail = async (email: string) => {
		try {
			await this.firebase.ForgotPasswordResetEmail(email);
			return Result.rigth();
		} catch (error) {
			return Result.left("Ocurrio un error");
		}
	};

	register = async ({
		email,
		password,
		firstName,
		lastName,
	}: {
		email: string;
		password: string;
		firstName: string;
		lastName: string;
	}) => {
		try {
			const user = await this.firebase.signUpWithEmailAndPassword(
				email,
				password
			);
			await this.firebase.setDocInCollection("users", user.uid, {
				firstName: firstName,
				lastName: lastName,
				email: email,
			});
			return Result.rigth<UserModel>(
				new UserModel({ email, firstName, lastName, id: user.uid })
			);
		} catch (error) {
			console.log("error", error);
			return Result.left("Ocurrio un error");
		}
	};

	onAuthChange = (nextOrObserver: NextOrObserver<User>) => {
		return this.firebase.onAuthChange(nextOrObserver);
	};
}
