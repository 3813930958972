import Loading from 'components/Loading';
import Spinner from 'components/Spinner';
import { useUserState } from 'contexts/user_context';
import useOutput from 'hooks/useOutput';
import Card from 'pages/Favorites/components/CardFavorites'
import React, { useEffect, useState } from 'react'
import getTime from 'utils/dateHelpers';


interface Output {
  id: string;
  content:string;
  date: Date;
  favorite:boolean;
  relativeDate: string;
  templateType:string
}

export default function History() {

  const { user } = useUserState();
  let uid = user?.id as string

  const {getOutputFromDB,changeFavorites} = useOutput()

  const [historyOutputs,setHistoryOutputs] = useState<Output[]>();

  useEffect(() => {
    getData()
  },[])

  const getData = async () =>{
   
    const userID = user?.id as string
    const outputs = await getOutputFromDB({uid:userID})
    const copy:Output[] = []
    outputs.map(el =>{
      copy.push({
        id: el.id,
        content: el.content,
        date:el.date,
        favorite: el.favorite,
        relativeDate:getTime(el.date) as string,
        templateType: el.templateType
      })
    })
    setHistoryOutputs(copy)
  }
  const setFavorite = async (id:string) =>{
    console.log(id)
    const copy =  historyOutputs?.map(el =>{
         if(el.id === id){
           let docId = el.id
         changeFavorites({uid:uid,docId:docId,data:{favorite:!el.favorite}})
         return {
           ...el,
           favorite:!el.favorite
         }
         }
         return el
       })
       setHistoryOutputs(copy)
   }
   


  return (
    <div className='ml-12 mt-10'>
   <h2 className='text-2xl  ml-12 font-semibold font-inter flex items-center'>History</h2>
   {historyOutputs ? <div className="flex flex-col lg:grid lg:grid-cols-3 mt-12">
      {historyOutputs ? historyOutputs.map((el, index) =>{
          return <Card key={index} width="w-[80%]" content={el.content}  templateType={el.templateType} favorite={el.favorite} setFavorite={setFavorite}  id={el.id as string} date={el.relativeDate}/>
      }): null}
    </div> : <div className="flex items-center justify-center w-full h-[70vh]"><Spinner/></div> }
   
    </div>
  )
}
