import React, { useEffect, useState } from "react";
import TextInput from "components/TextInput";
import useOpenAI from "hooks/useOpenAI";
import { toast } from "react-toastify";
import Card from "../components/Card";
import { classNames } from "utils/helpers";
import Header from "../components/Header";
import ButtonPanel from "../components/ButtonPanel";
import ProductDescriptionIcon from "assets/img/icons/ProductDescription.svg";
import OutputIcon from "assets/img/icons/Idea.svg";
import { HistoryIcon } from "utils/iconsSVG";
import OutputPanel from "../components/OutputPanel";
import getTime from "utils/dateHelpers";
import useOutput from "hooks/useOutput";
import { useUserState } from "contexts/user_context";

interface Output {
  id?: string;
  content:string;
  date: Date;
  favorite:boolean;
  relativeDate: string;
  templateType?: string;
}

export default function DescriptionGenerator() {
  const {addOutputToDB, getHistorialByType} = useOutput()
  const { generateDescription } = useOpenAI();
  const { user } = useUserState();

  const [newOutputs, setNewOutputs] = useState<Output[]>([]);
  const [historyOutputs, setHistoryOutputs] = useState<Output[]>([]);
  const [product, setProduct] = useState({
    productName: "",
    keyBenefitsFeatures: "",
    toneOfVoice: "",
  });
  const [numberOutputs, setNumberOutputs] = useState(1);

  useEffect(() => {
    setInterval(() => {
      if(newOutputs.length > 0){
        const copy:Output[] = newOutputs.map(el =>{
          return {
            ...el,
            relativeDate: getTime(el.date) as string,
          }
        })
        setNewOutputs(copy); 
      }
      if(historyOutputs.length > 0){
        const copy:Output[] = historyOutputs.map(el =>{
          return {
            ...el,
            relativeDate: getTime(el.date) as string,
          }
        })
        setHistoryOutputs(copy); 
      }
    },60000)  
  },[])

  useEffect(()  => {
    getData() 
   },[newOutputs])

   const getData = async () => {
    let uid = user?.id as string
    const outputs = await getHistorialByType({uid:uid,templateType:"Product Description"})
    const copy:Output[] = []
    outputs.map(el =>{
      copy.push({
        id: el.id,
        content: el.content,
        date: el.date,
        relativeDate: getTime(el.date) as string,
        favorite:el.favorite
      })
    })
    setHistoryOutputs(copy)
  }

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const toastId = toast.loading("Generating content...");
    const result = await generateDescription({
      productName: product.productName,
      keyBenefitsFeatures: product.keyBenefitsFeatures,
      toneOfVoice: product.toneOfVoice,
      numberOutputs: numberOutputs,
    });
    toast.dismiss(toastId);
    if (result.isRigth) {
      const value = result.getValue();
      console.log(value.choices[0].text)
      const outputs:string[] = value.choices[0].text.split("\n\n")
      const copyOutputs = newOutputs.filter((output)=> output);
      const temporalOutputs:Output[] = []
      for(let i = 0; i < outputs.length; i++) {
        if(outputs[i] !== "") {
          outputs[i] = handleWrittingOutput(outputs[i])
          temporalOutputs.unshift({
            content: outputs[i],
            date: new Date(),
            relativeDate: getTime(new Date()) as string,
            favorite:false,
            templateType:"Product Description"
          });
          copyOutputs.unshift({
            content: outputs[i],
            date: new Date(),
            relativeDate: getTime(new Date()) as string,
            favorite:false,
            templateType:"Product Description"
          });
        }
      }
      temporalOutputs.map(el =>{
        const newOutput = {
          content: el.content,
          date: el.date,
          favorite: false,
          templateType:"Product Description",
          uid: user?.id as string,
          inputs: {
            productName: product.productName,
            keyBenefitsFeatures: product.keyBenefitsFeatures,
            toneOfVoice: product.toneOfVoice,
          }
        }
        addOutputToDB(newOutput)
      })
      setNewOutputs(copyOutputs)
    } else {
      toast.error(result.getError());
    }
  };

  const handleWrittingOutput = (element: string) => {
    const search = element.match(/(?<=[0-9]\.).*/);
    const result = search?.length ? search[0].trim() : ""
    return result
  };

  const handleReset = (e: React.FormEvent<HTMLFormElement>) => {
    setProduct({
      productName: "",
      keyBenefitsFeatures: "",
      toneOfVoice: "",
    });
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setProduct({ ...product, [e.target.name]: e.target.value });
  };

  const handleChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setProduct({
      ...product,
      [e.target.name]: e.target.options[e.target.selectedIndex].innerHTML,
    });
  };

  const handleChangeNumberOutputs = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    let value = e.target.value;
    if (parseInt(value) === 0) value = "1";
    setNumberOutputs(parseInt(value));
  };

  return (
    <div className="flex flex-col h-fit">
      <Header
        title={"Amazon Product Description (paragraph)"}
        subtitle={
          "Artificial intelligence trained to write high-converting product descriptions in seconds."
        }
        icon={ProductDescriptionIcon}
      />
      <div className="flex flex-col  lg:flex-row h-full mt-8">
        <div className="relative bg-white border-t lg:border lg:w-[50%] lg:h-[72vh] mb-10 lg:mb-0 border-[#d9d9d9]">
          <form onSubmit={onSubmit} onReset={handleReset}>
            <div className=" flex flex-col w-full mt-4">
              <label
                className="ml-7 lg:ml-10 mb-2 font-semibold font-inter text-gray-600 text-sm"
                htmlFor="productName"
              >
                Product name*
              </label>
              <div className="mx-auto w-[85%]">
                <TextInput
                  className="h-9 mx-auto"
                  name="productName"
                  value={product.productName}
                  onChange={handleChange}
                  placeholder=""
                />
              </div>
            </div>
            <div className="flex flex-col w-full mt-4">
              <label
                className="ml-7 lg:ml-10 mb-2 font-semibold font-inter text-gray-600 text-sm"
                htmlFor="productName"
              >
                Key benefits/feautures *
              </label>
              <div className="mx-auto w-[85%]">
                <TextInput
                  multiline
                  className="mx-auto"
                  name="keyBenefitsFeatures"
                  value={product.keyBenefitsFeatures}
                  onChange={handleChange}
                  placeholder=""
                />
              </div>
            </div>
            <div className="flex flex-col w-full mt-4">
              <label
                className="ml-7 lg:ml-10 mb-2 font-semibold font-inter text-gray-600 text-sm"
                htmlFor="productName"
              >
                Tone of voice *
              </label>
              <div className="mx-auto w-[85%]">
                <select
                  className="h-9 mx-auto w-full font-inter text-base focus:outline-none border focus:ring-1 rounded-md text-gray-600 border-gray-400 focus:ring-primary"
                  name="toneOfVoice"
                  onChange={handleChangeSelect}
                  defaultValue="default"
                >
                  <option disabled hidden value="default"></option>
                  <option value="casual">Casual</option>
                  <option value="formal">Formal</option>
                  <option value="formal">Professional</option>
                </select>
                {/* <TextInput
                  className="h-9 mx-auto"
                  name="toneOfVoice"
                  value={product.toneOfVoice}
                  onChange={handleChange}
                  placeholder=""
                /> */}
              </div>
            </div>
            <ButtonPanel
              numberOutputs={numberOutputs}
              onChange={handleChangeNumberOutputs}
              showOutputController={true}
            />
          </form>
        </div>
        <OutputPanel newOutputs={newOutputs} historyOutputs={historyOutputs} setHistoryOutputs={setHistoryOutputs} />
      </div>
    </div>
  );
}

