import Button from "components/Button";
import Input from "components/Input";
import { useState } from "react";
import NavBar from "../components/NavBar";
import SignUpGoogle from "../components/SignUpGoogle";
import { ReactComponent as Arrow } from "assets/img/icons/Arrow 2.svg";
import useAuth from "hooks/useAuth";
import { useNavigate } from "react-router-dom";

function SignUp() {
	const navigate = useNavigate();
	const { signUpWithEmail } = useAuth();

	const [user, setUser] = useState({
		firstname: "",
		lastname: "",
		email: "",
		password: "",
	});

	// console.log("user", user);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setUser({ ...user, [e.target.name]: e.target.value });
	};

	const register = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		let result = await signUpWithEmail({
			email: user.email,
			password: user.password,
			firstName: user.firstname,
			lastName: user.lastname,
		});
		if (result.isRigth) {
			console.log("Register OK");
			navigate("/dashboard");
		} else {
			console.log("result.getError()", result.getError());
		}
	};

	return (
		<div className="flex flex-col items-center w-full">
			<NavBar
				primaryText="Have an account?"
				secondaryText="Sign In"
				link="/sign-in"
			/>
			<div className="flex flex-col items-center w-[70%] md:w-[50%] lg:w-[35%]">
				<h2 className="text-4xl font-semibold text-center mt-6">
					Start your free trial
				</h2>
				<SignUpGoogle />
				<div className="flex flex-row w-full h-10">
					<div className="flex items-center h-10 w-2/5">
						<div className="border-t h-1 w-full"></div>
					</div>
					<div className="flex items-center justify-center h-10 w-1/5">or</div>
					<div className="flex items-center h-10 w-2/5">
						<div className="border-t h-1 w-full"></div>
					</div>
				</div>
				<form className="pt-6 mb-4 w-full" onSubmit={register}>
					<div className="grid grid-cols-2">
						<div className="mb-4 mr-4 col-span-1">
							<Input
								value={user.firstname}
								handleChange={handleChange}
								label="First Name"
								linkedName="firstname"
								inputType="text"
							/>
						</div>
						<div className="mb-4 ml-4 col-span-1">
							<Input
								value={user.lastname}
								handleChange={handleChange}
								label="Last Name"
								linkedName="lastname"
								inputType="text"
							/>
						</div>
					</div>
					<div className="mb-6">
						<Input
							value={user.email}
							handleChange={handleChange}
							label="E-mail"
							linkedName="email"
							inputType="email"
						/>
						<Input
							value={user.password}
							handleChange={handleChange}
							label="Password"
							linkedName="password"
							inputType="password"
						/>
					</div>
					<Button height="h-11" type="submit" background={"bg-disabled"} textColor={"text-text-secondary"}>
						Next <Arrow className="ml-2 h-3" />
					</Button>
				</form>
				<div>
					<p className="text-center">
						<span className="opacity-60">
							We’re committed to your privacy. HubSpot uses the information you
							provide to us to contact you about our relevant content, products,
							and services. You may unsubscribe from these communications at any
							time. For more information, check out our
						</span>{" "}
						<span className="text-blue">Privacy Policy</span>
					</p>
				</div>
			</div>
		</div>
	);
}

export default SignUp;
