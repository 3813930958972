import Button from 'components/Button';
import Form from 'components/Form';
import Spinner from 'components/Spinner';
import TextInput from 'components/TextInput';
import useAuth from 'hooks/useAuth';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import NavBar from '../components/NavBar';

export default function ForgotPassword() {

    const { forgotPasswordEmail } = useAuth()

    const navigate = useNavigate();
	const [user, setUser] = useState({
		email: "",
	});

    const [loading,setLoading] = useState(false)

    const handleChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setUser({ ...user, [e.target.name]: e.target.value });
	};

	const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setLoading(true)
		const result = await forgotPasswordEmail(user.email);
		if (result.isRigth) {
            toast("Success Operation")
			setLoading(false)	
			navigate("/sign-in");
		}
		else{
			setLoading(false)	
			toast.error(result.getError());
		}
	};


  return (
    <div className="flex flex-col items-center w-full">
			<NavBar
				primaryText="Don't have an account?"
				secondaryText="Sign Up"
				link="/sign-up"
			/>
			<div className="flex flex-col items-center w-[70%] md:w-[50%] lg:w-[35%]">
				<h2 className="text-4xl font-semibold text-center mt-6">
					Forgot Password
				</h2>
				<Form className="pt-6 mb-4 w-full" onSubmit={onSubmit}>
					<TextInput
						className=" mt-6 mb-8"
						value={user.email}
						onChange={handleChange}
						placeholder="E-mail"
						name="email"
						type="email"
						validate={(value) => {
							if (value === "") {
								return "Required field";
							}
							return "";
						}}
					/>					
					<Button type="submit" background={"bg-disabled"} textColor={"text-text-secondary"} height="h-11">{loading ? <Spinner/>: <><span>Reset Password</span> </>}</Button>
					
				</Form>
			</div>
		</div>
  )
}
