import ProfileServices from "services/profile_services";
import { useInjection } from "utils/dependency_injection";

export default function useProfile() {
	const profileServices = useInjection<ProfileServices>("ProfileServices");

	const getProfile = (uid: string) => {
		return profileServices.getProfile(uid);
	};

	return { getProfile };
}
