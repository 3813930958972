import NavBar from "components/NavBar";
import SideBar from "components/SideBar";
import { Outlet } from "react-router-dom";

export default function Layout() {
	return (
		<div className=" flex h-screen w-screen">
			<div className="w-1/6">
				<SideBar />
			</div>
			<div className="bg-dashboard w-5/6 h-screen overflow-y-scroll">
				<NavBar />
				<div className="pt-12">
					<Outlet />
				</div>
			</div>
		</div>
	);
}
