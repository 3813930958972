import { FormEvent, ReactNode } from "react";
import { toast } from "react-toastify";

type FormProps = {
	children: ReactNode;
	onSubmit: (e: FormEvent<HTMLFormElement>) => void;
	className: string;
};

export default function Form({
	children,
	onSubmit,
	className,
	...props
}: FormProps) {
	return (
		<form
			className={className}
			{...props}
			onSubmit={(e) => {
				e.preventDefault();
				let hasError = false;
				const inputs = document.forms[0];
				for (let index = 0; index < inputs.length; index++) {
					const element: any = inputs[index];
					const errorElement = document.getElementById(
						`error-message-${element.name}`
					);
					if (errorElement) {
						errorElement.classList.remove("invisible");
						errorElement.classList.remove("absolute");
						errorElement.classList.add("visible");
						element.classList.add("border-red-600");
						hasError = true;
					}
				}
				if (!hasError) {
					onSubmit(e);
				} else {
					toast.error("Hay errores en el formulario");
				}
			}}
		>
			{children}
		</form>
	);
}
