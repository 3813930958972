import { Result } from "models/core/Result";
import { Configuration, OpenAIApi } from "openai";

export default class OpenAIServices {
	private configuration: Configuration;
	private openai: OpenAIApi;

	constructor() {
		this.configuration = new Configuration({
			apiKey: process.env.REACT_APP_OPENAI_API_KEY,
		});
		this.openai = new OpenAIApi(this.configuration);
	}

	generateDescription = async ({
		productName,
		keyBenefitsFeatures,
		toneOfVoice,
		numberOutputs
	}: {
		productName: string;
		keyBenefitsFeatures: string;
		toneOfVoice: string;
		numberOutputs: number;
	}): Promise<Result<any>> => {
		try {
			var writeForm;
			if(numberOutputs === 1){
				writeForm = ""
			}
			else{
				writeForm = "Answer in this format:\n"
				for (let i = 0; i < numberOutputs; i++) {
					writeForm += `${i+1}.\n\n`
				}
			}
			const response = await this.openai.createCompletion({
				model: "text-davinci-002",
				prompt: `Write ${numberOutputs} product descriptions.\n Product name: ${productName}\nKey benefits/feautures: ${keyBenefitsFeatures}\nTone of voice: ${toneOfVoice}\n${writeForm}`,
				max_tokens: 200,
				temperature: 0,
			});
			return Result.rigth(response.data);
		} catch (error: any) {
			return Result.left(error.toString());
		}
	};
	generateReview = async ({
		productName,
		toneOfVoice,
		rating,
		numberOutputs
	}: {
		productName: string;
		toneOfVoice: string;
		rating: string;
		numberOutputs: number;
	}): Promise<Result<any>> => {
		try {
			var writeForm;
			if(numberOutputs === 1){
				writeForm = ""
			}
			else{
				writeForm = "Answer in this format:\n"
				for (let i = 0; i < numberOutputs; i++) {
					writeForm += `${i+1}.\n\n`
				}
			}
			const response = await this.openai.createCompletion({
				model: "text-davinci-002",
				prompt: `Write ${numberOutputs} reviews for a product .\n Product name: ${productName}\nTone of voice: ${toneOfVoice}\nRating: ${rating}\n${writeForm}`,
				max_tokens: 200,
				temperature: 0,
			});
			return Result.rigth(response.data);
		} catch (error: any) {
			return Result.left(error.toString());
		}
	};
	generateExistingCopy = async ({
		copy,
		numberOutputs
	}: {
		copy: string;
		numberOutputs:number
	}): Promise<Result<any>> => {
		try {
			const response = await this.openai.createCompletion({
				model: "text-davinci-002",
				prompt: `Give ${numberOutputs}.\nImprove existing copy.\n Copy: ${copy}\n`,
				max_tokens: 200,
				temperature: 0,
			});
			return Result.rigth(response.data);
		} catch (error: any) {
			return Result.left(error.toString());
		}
	};
	generateKeywords = async ({
		productName,
		productDescription,
		numberOfKeywords
	}: {
		productName: string;
		productDescription: string;
		numberOfKeywords:number
	}): Promise<Result<any>> => {
		try {
			var writeForm;
			if(numberOfKeywords === 1){
				writeForm = ""
			}
			else{
				writeForm = "Answer in this format:\n"
				for (let i = 0; i < numberOfKeywords; i++) {
					writeForm += `${i+1}.\n\n`
				}
			}
			const response = await this.openai.createCompletion({
				model: "text-davinci-002",
				prompt: `Generate keywords from a product name.\n Product name: ${productName}\nProduct Description: ${productDescription}\nNumber of keywords: ${numberOfKeywords}\n${writeForm}`,
				max_tokens: 200,
				temperature: 0,
			});
			return Result.rigth(response.data);
		} catch (error: any) {
			return Result.left(error.toString());
		}
	};
}
