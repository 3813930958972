import "reflect-metadata";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import {
	DependencyProvider,
	registerDependencies,
} from "utils/dependency_injection";
import { container } from "tsyringe";
import { UserProvider } from "contexts/user_context";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

registerDependencies();

const contextClass = {
	success: "bg-green-600",
	error: "bg-red-600",
	info: "bg-blue-600",
	warning: "bg-orange-500",
	default: "bg-indigo-600",
	dark: "bg-white-600 font-gray-300",
};

root.render(
	<>
		<ToastContainer
			position="top-right"
			autoClose={5000}
			hideProgressBar={true}
			newestOnTop={false}
			theme="colored"
			toastClassName={(options) =>
				contextClass[options?.type || "default"] +
				" relative flex p-1 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer mb-2"
			}
			bodyClassName={() => "text-sm font-white flex font-med p-3"}
			// closeOnClick
			// rtl={false}
			// pauseOnFocusLoss
			// draggable
			// pauseOnHover
		/>
		<BrowserRouter>
			<DependencyProvider container={container}>
				<UserProvider>
					<App />
				</UserProvider>
			</DependencyProvider>
		</BrowserRouter>
	</>
);
