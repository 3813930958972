import React, { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { classNames } from "utils/helpers";
import { CopyIcon, FavoritesIcon } from "utils/iconsSVG";

export default function Card({
  content,
  date,
  width,
  templateType,
  favorite,
  setFavorite,
  id
}: {
  
  content?: string;
  date?: string;
  width?: string;
  templateType?: string;
  favorite:boolean;
  setFavorite: (id:string)=>Promise<void>;
  id:string
}) {
  const navigate = useNavigate();

 
  return (
    <div
      onClick={() => {
        
      }}
      className={classNames(
        "my-8 lg:mb-0 h-80 lg:h-[280px] rounded-lg py-4 shadow-xl mx-6 cursor-pointer",
        width ? width : ""
      )}
    >
      
        <div className="flex flex-col px-4 overflow-hidden h-full">
          <div className="flex justify-between items-center h-[10%]">
            <span className="text-gray-600 font-inter">{templateType}</span>
            <div className="flex items-center ml-auto mr-5 ">
            {id ? <div onClick={()=>setFavorite(id)}><FavoritesIcon fill={favorite?"#F00":"#555"} className="h-4 mr-2"/></div> : <div onClick={()=>setFavorite(id)} ><FavoritesIcon fill={favorite?"#F00":"#555"} className="h-5 ml-1"/></div>}    
            <CopyIcon fill="#555" className="" />
            </div>
          </div>
          <div className="px-6 py-4 overflow-hidden text-ellipsis h-[80%]">
            <p className="text-gray-500 text-base overflow-hidden text-ellipsis">{content}</p>
          </div>
          <div className="ml-auto">{date}</div>
        </div>
     
    </div>
  );
}
