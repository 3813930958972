import React, { useEffect, useState } from "react";
import TextInput from "components/TextInput";
import useOpenAI from "hooks/useOpenAI";
import { toast } from "react-toastify";
import Card from "../components/Card";
import { classNames } from "utils/helpers";
import Header from "../components/Header";
import ButtonPanel from "../components/ButtonPanel";
import ReviewIcon from "assets/img/icons/Reviews.svg"
import { HistoryIcon } from "utils/iconsSVG";
import OutputIcon from "assets/img/icons/Idea.svg"
import OutputPanel from "../components/OutputPanel";
import getTime from "utils/dateHelpers";
import { useUserState } from "contexts/user_context";
import useOutput from "hooks/useOutput";

interface Output {
  id?: string;
  content:string;
  date: Date;
  favorite:boolean;
  relativeDate: string;
  templateType?: string;
}

export default function ReviewGenerator() {
  const {addOutputToDB, getHistorialByType} = useOutput()
  const { generateReview } = useOpenAI();
  const { user } = useUserState();
  
  const [newOutputs, setNewOutputs] = useState<Output[]>([]);
  const [historyOutputs, setHistoryOutputs] = useState<Output[]>([]);
  const [product, setProduct] = useState({
    productName: "",
    toneOfVoice: "",
    rating: "",
  });
  
  const [numberOutputs, setNumberOutputs] = useState(1);

  useEffect(() => {
    setInterval(() => {
      if(newOutputs.length > 0){
        const copy:Output[] = newOutputs.map(el =>{
          return {
            ...el,
            relativeDate: getTime(el.date) as string,
          }
        })
        setNewOutputs(copy); 
      }
      if(historyOutputs.length > 0){
        const copy:Output[] = historyOutputs.map(el =>{
          return {
            ...el,
            relativeDate: getTime(el.date) as string,
          }
        })
        setHistoryOutputs(copy); 
      }
    },60000)  
  },[])

  useEffect(()  => {
    getData() 
   },[newOutputs])

   const getData = async () => {
    let uid = user?.id as string
    const outputs = await getHistorialByType({uid:uid,templateType:"Reviews"})
    const copy:Output[] = []
    outputs.map(el =>{
      copy.push({
        id: el.id,
        content: el.content,
        date: el.date,
        relativeDate: getTime(el.date) as string,
        favorite:el.favorite
      })
    })
    setHistoryOutputs(copy)
  }

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const toastId = toast.loading("Generating content...");
    const result = await generateReview({
      productName: product.productName,
      toneOfVoice: product.toneOfVoice,
      rating: product.rating,
      numberOutputs: numberOutputs,
    });
    toast.dismiss(toastId);
    if (result.isRigth) {
      const value = result.getValue();
      console.log(value.choices[0].text)
      const outputs:string[] = value.choices[0].text.split("\n\n")
      const copyOutputs = newOutputs.filter((output)=> output);
      const temporalOutputs:Output[] = []
      for(let i = 0; i < outputs.length; i++) {
        if(outputs[i] !== "") {
          outputs[i] = handleWrittingOutput(outputs[i])
          temporalOutputs.unshift({
            content: outputs[i],
            date: new Date(),
            relativeDate: getTime(new Date()) as string,
            favorite:false,
            templateType:"Reviews"
          });
          copyOutputs.unshift({
            content: outputs[i],
            date: new Date(),
            relativeDate: getTime(new Date()) as string,
            favorite:false,
            templateType:"Reviews"
          });
        }
      }
      temporalOutputs.map(el =>{
        const newOutput = {
          content: el.content,
          date: el.date,
          favorite: false,
          templateType:"Reviews",
          uid: user?.id as string,
          inputs: {
            productName: product.productName,
            toneOfVoice: product.toneOfVoice,
            rating: product.rating,
          }
        }
        addOutputToDB(newOutput)
      })
      setNewOutputs(copyOutputs)
    } else {
      toast.error(result.getError());
    }
  };

  const handleWrittingOutput = (element: string) => {
    const search = element.match(/(?<=[0-9]\.).*/);
    const result = search?.length ? search[0].trim() : ""
    return result
  };
  
  const handleReset = (e: React.FormEvent<HTMLFormElement>) => {
    setProduct({
      productName: "",
      toneOfVoice: "",
      rating:""
    });
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setProduct({ ...product, [e.target.name]: e.target.value });
  };

  const handleChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setProduct({
      ...product,
      [e.target.name]: e.target.options[e.target.selectedIndex].innerHTML,
    });
  };

  const handleChangeNumberOutputs = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value.replace(/\+|-/gi, "");
                    if (parseInt(value) === 0) value = "1";
                    setNumberOutputs(parseInt(value));
  }

  return (
    <div className="flex flex-col h-fit">
      <Header
        title={"Reviews"}
        subtitle={
          "Artificial intelligence trained to write high-converting product reviews in seconds."
        } icon={ReviewIcon}
      />
      <div className="flex flex-col  lg:flex-row h-full mt-8">
        <div className="relative bg-white border-t lg:border lg:w-[50%] lg:h-[72vh] mb-10 lg:mb-0 border-[#d9d9d9]">
          <form  onSubmit={onSubmit} onReset={handleReset}>
            <div className="flex flex-col w-full mt-4">
              <label className="ml-7 lg:ml-10 mb-2 font-semibold text-gray-600 font-inter text-sm" htmlFor="productName">
                Product name*
              </label>
              <div className="mx-auto w-[85%]">
                <TextInput
                  className="h-9 mx-auto"
                  name="productName"
                  value={product.productName}
                  onChange={handleChange}
                  placeholder=""
                />
              </div>
            </div>
            <div className="flex flex-col w-full mt-4">
              <label className="ml-7 lg:ml-10 mb-2 font-semibold text-gray-600 font-inter text-sm" htmlFor="productName">
                Tone of voice *
              </label>
              <div className="mx-auto w-[85%]">
                <select
                  className="h-9 mx-auto w-full  font-inter   text-base focus:outline-none border focus:ring-1 rounded-lg text-gray-600 border-gray-400 focus:ring-primary"
                  name="toneOfVoice"
                  onChange={handleChangeSelect}
                  defaultValue="default"
                >
                  <option disabled hidden value="default"></option>
                  <option value="casual">Casual</option>
                  <option value="formal">Formal</option>
                  <option value="formal">Professional</option>
                </select>
                {/* <TextInput
                  className="h-9 mx-auto"
                  name="toneOfVoice"
                  value={product.toneOfVoice}
                  onChange={handleChange}
                  placeholder=""
                /> */}
              </div>
            </div>
            <div className="flex flex-col w-full mt-4">
              <label className="ml-7 lg:ml-10 mb-2 font-semibold text-gray-600 font-inter text-sm" htmlFor="productName">
                Rating *
              </label>
              <div className="mx-auto w-[85%]">
                <select
                  className="h-9 mx-auto w-full  text-base focus:outline-none border focus:ring-1 rounded-lg text-gray-600 border-gray-400 focus:ring-primary"
                  name="rating"
                  onChange={handleChangeSelect}
                  defaultValue="default"
                >
                  <option disabled hidden value="default"></option>
                  <option value="0 stars">0 star</option>
                  <option value="1 star">1 star</option>
                  <option value="2 stars">2 stars</option>
                  <option value="3 stars">3 stars</option>
                  <option value="4 stars">4 stars</option>
                  <option value="5 stars">5 stars</option>
                </select>
                {/* <TextInput
                  className="h-9 mx-auto"
                  name="toneOfVoice"
                  value={product.toneOfVoice}
                  onChange={handleChange}
                  placeholder=""
                /> */}
              </div>
            </div>
           
            <ButtonPanel numberOutputs={numberOutputs} onChange={handleChangeNumberOutputs} showOutputController={true}/>
            
          </form>
        </div>
        <OutputPanel  newOutputs={newOutputs} historyOutputs={historyOutputs} setHistoryOutputs={setHistoryOutputs} />
      </div>
    </div>
  );
}
