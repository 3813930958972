import Firebase from "utils/firebase";
import { Result } from "models/core/Result";
import Output from "models/Output";

export default class OutputServices {
  private firebase: Firebase;

  constructor(firebase: Firebase) {
    this.firebase = firebase;
  }

  addOutputToDB = async (
    content: string,
    date: Date,
    favorite: boolean,
    templateType: string,
    uid: string,
    inputs:{}
  ) => {
    await this.firebase.addDocToSubCollection("users",uid,"outputs",{
      content: content,
      date: date,
      favorite: favorite,
      templateType: templateType,
      inputs:inputs
    });
    return Result.rigth();
  };

  getOutputFromDB = async (uid: string) => {
    const data = await this.firebase.getDocsInSubCollection("users",uid,"outputs","date");
    data.map(el => {
      el.date = el.date.toDate();
    })
    
    return data;
  };

  getHistorialByType = async (uid: string, templateType: string) => {
  
    const data = await this.firebase.getDocsInSubCollectionByCond("users",uid,"outputs","date","templateType",templateType)
   
    data.map(el =>{
      el.date = el.date.toDate();
    })

    return data;
  };

  getFavorites = async (uid: string) => {
    const data = await this.firebase.getDocsInSubCollectionByCond("users",uid,"outputs","date","favorite",true)
    
    data.map(el =>{
      el.date = el.date.toDate();
    })
    
    return data;
  };

  setFavorites = async (uid:string,data:any,docId:string) => {
   return await this.firebase.updateDocInSubCollection("users",uid,"outputs",docId,data)
  }
}
