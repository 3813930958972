import React from "react";
import Circle from "assets/img/icons/Active.svg";
import {UserIcon} from "utils/iconsSVG"
import { useUserState } from "contexts/user_context";
import {ReactComponent as AddIcon} from 'assets/img/icons/Big Add.svg'

export default function DashBoard() {
  const {user} = useUserState();

  return (
   
      <div className="w-full py-12">
        <div className="flex items-center justify-center lg:justify-start lg:ml-12">
          <div>
            <UserIcon fill={"#f19e38"} className={"shadow-xl rounded-full h-12"}/>
          </div>
          <div className="ml-4 w-[60%]">
            <h2 className="font-semibold">Good Afternoon, {user?.firstName}!</h2>
            <p className="text-sm text-gray-500">
              Here is an overview of your catalogs performance
            </p>
          </div>
        </div>
        <div className=" flex flex-col w-full items-center mt-32">
         <div className="flex flex-col h-fit lg:h-[30vh] w-[85%] shadow-xl">
           <div className="flex justify-between">
             <h2 className="font-inter ml-4">Favorite Templates</h2>
             <h3 className="font-inter text-secondary text-sm mr-4">See all</h3>
           </div>
           <hr className="w-[97%] mx-auto mt-2" />
           <AddIcon className="lg:h-[40vh] mt-2 mb-2"/>
         </div>
         <div className="flex flex-col h-fit w-[85%] shadow-xl mt-16">
           <div className="flex justify-between my-4">
             <h2 className="font-inter ml-4">Credit Balance</h2>
             <h3 className="font-inter text-secondary text-sm mr-4">View usage details</h3>
           </div>
           <h3 className="font-inter text-xs ml-4 text-gray-500">How many credits you have available in your plan</h3>
           <div className="flex flex-col items-center lg:items-start lg:flex-row justify-between mt-16 mb-10">
             <div className="flex flex-col w-[50%] lg:w-[33%] items-center border border-gray-300 rounded-lg mx-4 py-4 mb-4 lg:mb-0"><h2 className="font-inter text-gray-500 mb-1">Total credits</h2><span className="font-inter font-semibold text-xl">17,000</span></div>
             <div className="flex flex-col w-[50%] lg:w-[33%] items-center border border-gray-300 rounded-lg mx-4 py-4 mb-4 lg:mb-0"><h2 className="font-inter text-gray-500 mb-1">Plan credits</h2><span className="font-inter font-semibold text-xl">20,000</span></div>
             <div className="flex flex-col w-[50%] lg:w-[33%] items-center border border-gray-300 rounded-lg mx-4 py-4 mb-4 lg:mb-0"><h2 className="font-inter text-gray-500 mb-1">Bonus credits</h2><span className="font-inter font-semibold text-xl">5,000</span></div>
           </div>
         </div>
        </div>
      </div>
    
  );
}
