import Card from "components/CardTemplates";
import React from "react";
import {ReactComponent as ProductDescription} from 'assets/img/icons/ProductDescription.svg';
import {ReactComponent as Reviews} from 'assets/img/icons/Reviews.svg';
import {ReactComponent as Keywords} from 'assets/img/icons/Keywords.svg';
import {ReactComponent as ExistingCopy} from 'assets/img/icons/ExistingCopy.svg';


export default function Templates() {

   let cards = [
       {
           title: "Amazon Product Description (paragraph)",
           icon: <ProductDescription className="h-12"/>,
           description:"Artificial intelligence trained to write high-converting product descriptions in seconds. ",
           link: '/templates/descriptionGenerator'
       },
       {
           title: "Reviews",
           icon: <Reviews className="h-12"/>,
           description:"Artificial intelligence trained to write high-converting product reviews in seconds.",
           link: '/templates/reviewGenerator'
       },
       {
           title: "Existing Copy",
           icon: <ExistingCopy className="h-12"/>,
           description:"Artificial intelligence trained to write high-converting product exiting copy in seconds.",
           link: '/templates/existingCopyGenerator'
       },
       {
        title: "Keywords from a Product",
        icon: <Keywords className="h-12"/>,
        description:"Artificial intelligence trained to write high-converting product keywords from a product name and description in seconds.",
        link: '/templates/keyWordGenerator'
      }
   ]

  return (
    <div className="flex flex-col px-5 lg:px-0">
      <div className="flex flex-col items-center px-4 lg:px-0 lg:items-start lg:ml-12 mt-4">
      <h2 className="text-2xl font-semibold">Templates</h2>
      <p className="lg:w-128 text-sm text-gray-500">
        Explore our growing library of powerful tools purpose built for writing
        persuasive and engaging content for your Amazon Store.
      </p>
      </div>
      <div className="bg-secondary text-text-primary rounded-lg lg:ml-8 w-8 h-8 flex items-center justify-center mt-8">All</div>

    <div className="flex flex-col w-full lg:grid lg:grid-cols-3 mt-7 mb-10">
      {cards.map((el,index) =>{
          return <Card width="w-[90%] lg:w-[80%]" link={el.link} key={index} title={el.title} description={el.description} icon={el.icon} />
      })}
    </div>
    </div>
  );
}
