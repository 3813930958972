import React, { useEffect, useState } from "react";
import TextInput from "components/TextInput";
import useOpenAI from "hooks/useOpenAI";
import { toast } from "react-toastify";
import Card from "../components/Card";
import { classNames } from "utils/helpers";
import Header from "../components/Header";
import ButtonPanel from "../components/ButtonPanel";
import KeywordIcon from "assets/img/icons/Keywords.svg"
import OutputIcon from "assets/img/icons/Idea.svg"
import { HistoryIcon } from "utils/iconsSVG";
import OutputPanel from "../components/OutputPanel";
import getTime from "utils/dateHelpers";
import useOutput from "hooks/useOutput";
import { useUserState } from "contexts/user_context";

interface Output {
  id?: string;
  content:string;
  date: Date;
  favorite:boolean;
  relativeDate: string;
  templateType?:string
}

export default function DescriptionGenerator() {
  const {addOutputToDB, getHistorialByType} = useOutput()
  const { generateKeywords } = useOpenAI();
  const { user } = useUserState();

 
  const [newOutputs, setNewOutputs] = useState<Output[]>([]);
  const [historyOutputs, setHistoryOutputs] = useState<Output[]>([]);
  const [product, setProduct] = useState({
    productName: "",
    productDescription: "",
    numberOfKeywords: 1,
  });

  useEffect(() => {
    setInterval(() => {
      if(newOutputs.length > 0){
        const copy:Output[] = newOutputs.map(el =>{
          return {
            ...el,
            relativeDate: getTime(el.date) as string,
          }
        })
        setNewOutputs(copy); 
      }
      if(historyOutputs.length > 0){
        const copy:Output[] = historyOutputs.map(el =>{
          return {
            ...el,
            relativeDate: getTime(el.date) as string,
          }
        })
        setHistoryOutputs(copy); 
      }
    },60000)  
  }, [])

  useEffect(()  => {
    getData() 
   },[newOutputs])

   const getData = async () => {
    let uid = user?.id as string
    const outputs = await getHistorialByType({uid:uid,templateType:"Keywords Generator"})
    const copy:Output[] = []
    outputs.map(el =>{
      copy.push({
        id: el.id,
        content: el.content,
        date: el.date,
        relativeDate: getTime(el.date) as string,
        favorite:el.favorite
      })
    })
    setHistoryOutputs(copy)
  }

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const toastId = toast.loading("Generating content...");
    const result = await generateKeywords({
      productName: product.productName,
      productDescription: product.productDescription,
      numberOfKeywords: product.numberOfKeywords,
    });
    toast.dismiss(toastId);
    if (result.isRigth) {
      const value = result.getValue();
      console.log(value.choices[0].text)
      setNewOutputs([...newOutputs,{
        content: value.value.choices[0].text,
        date: new Date(),
        relativeDate: getTime(new Date()) as string,
        favorite:false,
        templateType:"Keywords Generator"
      }])
      const element = 
        {
          content: value.value.choices[0].text,
          date: new Date(),
          relativeDate: getTime(new Date()) as string,
          favorite:false,
          templateType:"Keywords Generator",
          uid: user?.id as string,
          inputs: {
            productName: product.productName,
            productDescription: product.productDescription,
            numberOfKeywords: product.numberOfKeywords,
          }
        }
        addOutputToDB(element)
    } else {
      toast.error(result.getError());
    }
  };
  
  const handleReset = (e: React.FormEvent<HTMLFormElement>) => {
    setProduct({
      productName: "",
      productDescription: "",
      numberOfKeywords: 1,
    });
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setProduct({ ...product, [e.target.name]: e.target.value });
  };

  const handleChangeNumberOutputs = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value.replace(/\+|-/gi, "");
        if (parseInt(value) === 0) value = "1";
        setProduct({
          ...product,
          numberOfKeywords:parseInt(value),
        });
      
  }

  return (
    <div className="flex flex-col h-fit">
      <Header
        title={"Keywords from a Product"}
        subtitle={
          "Artificial intelligence trained to write high-converting product keywords from a product name and description in seconds."
        }
        icon={KeywordIcon}
      />
      <div className="flex flex-col  lg:flex-row h-full mt-8">
        <div className="relative bg-white border-t lg:border lg:w-[50%] lg:h-[72vh] mb-10 lg:mb-0 border-[#d9d9d9]">
          <form onSubmit={onSubmit} onReset={handleReset}>
            <div className="flex flex-col w-full mt-4">
              <label className="ml-7 lg:ml-10 mb-2 font-semibold text-gray-600 font-inter text-sm" htmlFor="productName">
                Product name*
              </label>
              <div className="mx-auto w-[85%]">
                <TextInput
                  className="h-9 mx-auto"
                  name="productName"
                  value={product.productName}
                  onChange={handleChange}
                  placeholder=""
                />
              </div>
            </div>
            <div className="flex flex-col w-full mt-4">
              <label className="ml-7 lg:ml-10 mb-2 font-semibold text-gray-600 font-inter text-sm" htmlFor="productName">
                Product Description *
              </label>
              <div className="mx-auto w-[85%]">
                <TextInput
                  multiline
                  className="mx-auto"
                  name="productDescription"
                  value={product.productDescription}
                  onChange={handleChange}
                  placeholder=""
                />
              </div>
            </div>
            <div className="flex flex-col w-full ml-11 mt-4">
            <span className="mb-2 font-semibold text-gray-600 font-inter text-sm">Keywords *</span>
            <input
                  className="w-20 bg-transparent mr-1 border border-gray-400"
                  type="number"
                  value={product.numberOfKeywords}
                  onChange={handleChangeNumberOutputs}
                />
            </div>
            
            <ButtonPanel showOutputController={false}/>
          </form>
        </div>
        <OutputPanel newOutputs={newOutputs} historyOutputs={historyOutputs} setHistoryOutputs={setHistoryOutputs} />
      </div>
    </div>
  );
}
