import OpenAIServices from "services/openai_services";
import { useInjection } from "utils/dependency_injection";

export default function useOpenAI() {
	const openAIServices = useInjection<OpenAIServices>("OpenAIServices");

	const generateDescription = async ({
		productName,
		keyBenefitsFeatures,
		toneOfVoice,
		numberOutputs
	}: {
		productName: string;
		keyBenefitsFeatures: string;
		toneOfVoice: string;
		numberOutputs: number;
	}) => {
		return await openAIServices.generateDescription({
			productName,
			keyBenefitsFeatures,
			toneOfVoice,
			numberOutputs
		});
	};
	
	const generateReview = async ({
		productName,
		toneOfVoice,
		rating,
		numberOutputs
	}: {
		productName: string;
		toneOfVoice: string;
		rating: string;
		numberOutputs: number;
	}) => {
		return await openAIServices.generateReview({
			productName,
			toneOfVoice,
			rating,
			numberOutputs
		});
	};

	const generateExistingCopy = async ({
		copy,
		numberOutputs
	}: {
		copy: string;
		numberOutputs:number
	}) => {
		return await openAIServices.generateExistingCopy({
			copy,
			numberOutputs
		});
	};

	const generateKeywords = async ({
		productName,
		productDescription,
		numberOfKeywords
	}: {
		productName: string;
		productDescription: string;
		numberOfKeywords:number
	}) => {
		return await openAIServices.generateKeywords({
			productName,
			productDescription,
			numberOfKeywords
		});
	};

	return { generateDescription, generateReview, generateExistingCopy,generateKeywords };
}
