import NavBar from "pages/Home/components/NavBar";
import React, { useState } from "react";
import { classNames } from "utils/helpers";
import CardPlan from "./components/CardPlan";
import { ReactComponent as Circle } from "assets/img/icons/Active.svg";
import { ReactComponent as Arrow } from "assets/img/icons/Arrow 5.svg";
import Button from "components/Button";
import Footer from "../Home/components/Footer";
import Questions from "pages/Home/components/Questions";
export default function Pricing() {
  const [selected, setSelected] = useState<number>(1);
  //eslint-disable-next-line
  const [plans, setPlans] = useState([
    
    {
      title: "Starter",
      subtitle: "Best for new sellers 1-5 stores",
      priceXMonth: ["$9/mo*","$9 per month"],
      priceXYear: ["$97 billed anually","$97/year*"],
      features: [
        "Up to 5,000 words",
        "1 User",
        "Generate product descriptions",
        "Generate product names",
        "Generate product reviews",
        "Improve existing product copy",
        "Re-write exitent product copy",
        "Custumer support 24/7",
      ],
      mostPopular: false
    },
    {
      title: "Pro",
      subtitle: "Best for experienced sellers 5-25 stores",
      priceXMonth: ["$19/mo*","$19 per month"],
      priceXYear: ["$205 billed anually","$205/year*"],
      features: [
        "Up to 20,000 words",
        "Unlimited Users",
        "Generate product descriptions",
        "Generate product names",
        "Generate product reviews",
        "Improve existing product copy",
        "Re-write exitent product copy",
        "Custumer support 24/7",
      ],
      mostPopular: true
    },
    {
      title: "Enterprise",
      subtitle: "Best for stablished sellers 25+ stores",
      priceXMonth: ["$45/mo*","$45 per month"],
      priceXYear: ["$450 billed anually","$450/year*"],
      features: [
        "Up to 50,000 words",
        "Unlimited Users",
        "Generate product descriptions",
        "Generate product names",
        "Generate product reviews",
        "Improve existing product copy",
        "Re-write exitent product copy",
        "Custumer support 24/7",
      ],
      mostPopular: false
    },
  ]);

  return (
    <div className="bg-primary h-full w-full overflow-x-hidden">
      <NavBar />

      <div className="w-full flex flex-col items-center mt-14 px-4 lg:px-0">
        <h2 className="font-roboto font-semibold text-white text-4xl mb-1">
          Ready To Get Started?
        </h2>
        <p className="text-secondary">
          Try amzhub risk-free for 7 days. 100% money back guarantee.
        </p>
      </div>

      <div className="w-full flex justify-center mt-10">
        <div
          onClick={() => {
            setSelected(1);
          }}
          className={classNames(
            "h-10 w-20 flex items-center justify-center cursor-pointer ml-[10%] lg:ml-[3%]",
            selected === 1
              ? "bg-secondary text-primary border-t border-b border-l rounded-l-lg border-secondary"
              : "bg-transparent text-secondary border-t border-b border-l  rounded-l-lg border-secondary"
          )}
        >
          <span className="font-roboto">Monthly</span>
        </div>
        <div
          onClick={() => {
            setSelected(2);
          }}
          className={classNames(
            "h-10 w-20 flex items-center justify-center cursor-pointer",
            selected === 2
              ? "bg-secondary text-primary border-t border-b border-r rounded-r-lg border-secondary"
              : "bg-transparent text-secondary border-t border-b border-r  rounded-r-lg border-secondary"
          )}
        >
          <span className="font-roboto">Yearly</span>
        </div>
        <div className="relative -right-3 -top-6"><Arrow className="-ml-8"/><span className="text-secondary font-grace">save 10%</span></div>
      </div>

      <div className="flex w-[85%] mx-auto justify-center mb-16 lg:my-24">
        <div className="lg:relative flex flex-col items-center justify-end bg-brown h-fit lg:h-[680px] w-full mt-10 lg:z-[1] px-10">
          <div className="lg:relative flex flex-col items-center lg:flex-row lg:items-start lg:justify-between w-full h-full lg:z-[2] lg:-top-24">
            {plans.map((plan, index) => {
              return (
                <div className="w-full lg:w-[30%] mt-12 lg:mt-0 h-full border border-black bg-secondary rounded-xl shadow-lg">
                  <CardPlan
                    title={plan.title}
                    subtitle={plan.subtitle}
                    priceXMonth={plan.priceXMonth}
                    priceXYear={plan.priceXYear}
                    features={plan.features}
                    mostPopular={plan.mostPopular}
                    selected={selected}
                  />
                </div>
              );
            })}
          </div>
          <div className="flex w-full lg:ml-[15%] mt-8 lg:mt-0">
            <Circle className="h-16 w-16 opacity-50" />
            <div className="flex flex-col w-full lg:w-[550px] ml-4">
              <h2 className="text-white text-2xl font-roboto font-semibold">
                100% No-Risk Money Back Guarantee
              </h2>
              <p className="text-secondary mb-8">
                If you're not satisfied with the content our AI wrote for you,
                simply email hello@amzhub.io we’ll instantly refund 100% of your
                money. No questions asked. We are confident that when you sign
                up today, you'll get exactly what you need to take your Amazon
                store copy to the next level.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="w-full flex flex-col px-28">
        <h2 className="font-roboto font-semibold text-white">
          Questions? Answers.
        </h2>
        <p className="font-roboto text-sm  text-white mb-6">
          Don’t see your question below? Email us.
        </p>
        <div className="w-full h-[60vh] bg-brown mx-auto flex justify-center items-center"></div>
      </div> */}
      <Questions/>

      <div className="flex flex-col items-center justify-center bg-secondary my-12  w-full h-[250px]">
        <h2 className="text-2xl mb-2 font-semibold text-center">Get started with the leading AI copywriter for Amazon sellers</h2>
        <p className="mb-4">Build and grow a successful business with Amzhub.</p>
        <div className="lg:w-[20%]"><Button background={"bg-transparent"} borders={"border border-black rounded-xl"} textColor={"text-primary"} onClick={()=>{}}><span className="font-roboto px-2">Start Free Trial</span></Button></div>
        
      </div>
      <Footer/>
    </div>
  );
}
