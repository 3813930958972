import React from "react";
import GoogleIcon from "assets/img/icons/Google Icon.png"
import useAuth from "hooks/useAuth";

function SignUpGoogle() {
  const { logInWithGoogle } = useAuth();
  
  return (
    <div onClick={logInWithGoogle} className="flex flex-row w-full h-10 mt-8 cursor-pointer">
      <div className="bg-text-primary w-[15%] border-l border-t border-b border-gray-700 rounded-l flex justify-center items-center"></div>
      <div className="bg-blue w-[85%] text-text-primary  flex items-center justify-center rounded-r">
        <span className="font-roboto">Sign up with Google</span>
      </div>
    </div>
  );
}

export default SignUpGoogle;
