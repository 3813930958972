import { NextOrObserver, User } from "firebase/auth";
import AuthServices from "services/auth_services";
import { useInjection } from "utils/dependency_injection";

export default function useAuth() {
	const authServices = useInjection<AuthServices>("AuthServices");

	const signUpWithEmail = async ({
		email,
		password,
		firstName,
		lastName,
	}: {
		email: string;
		password: string;
		firstName: string;
		lastName: string;
	}) => {
		return await authServices.register({
			email,
			password,
			firstName,
			lastName,
		});
	}

	const logIn = async (email: string, password: string) => {
		return await authServices.login(email, password);
	};

	const logInWithGoogle = async () => {
		return await authServices.loginWithGoogle();
	};

	const forgotPasswordEmail = async (email:string) => {
		return await authServices.ForgotPasswordResetEmail(email);
	};

	const logOut = () => {
		return authServices.logOut();
	};

	const onAuthChange = (nextOrObserver: NextOrObserver<User>) => {
		return authServices.onAuthChange(nextOrObserver);
	};

	return { signUpWithEmail, logIn, logInWithGoogle, forgotPasswordEmail, logOut, onAuthChange };
}
