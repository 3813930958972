import React from "react";
import {ReactComponent as PlanIcon} from "assets/img/icons/Plan.svg";
import Button from "components/Button";
import useAuth from "hooks/useAuth";
import { useNavigate } from "react-router-dom";

export default function NavBar() {
	const navigate = useNavigate();
	const { logOut } = useAuth();

	const signOut = async (e: React.MouseEvent<HTMLButtonElement>) => {
		const result = await logOut();
		if (result.isRigth) {
			navigate("/");
			window.location.replace("");
		}
	};

	return (
		<header className="flex flex-row fixed w-5/6 items-center justify-end h-12 bg-text-primary">
			<div className="flex flex-row items-center">
				<div className="mr-6 h-8">
					<Button
						background="bg-secondary"
						textColor="text-text-primary"
						onClick={signOut}
						height="h-8"
					>
						<PlanIcon/>
						<span className="ml-2">Started plan</span>
					</Button>
				</div>
			</div>
		</header>
	);
}
