import Input from 'components/Input'
import React from 'react'

export default function Workspace() {
  return (
    <div className='mx-4 lg:ml-16 lg:mr-0 mt-10 mb-10 lg:mb-0 p-4 lg:p-10 h-full overflow-hidden shadow-xl lg:w-2/3 rounded-xl '>
        <div>
            <h2 className='text-xl font-inter font-semibold text-gray-600'>Company Information</h2>
            <p className='font-light text-gray-500'>Tell us about your business</p>
            
                <div className='flex flex-col mt-4'>
                    <div className='flex flex-col lg:flex-row w-full justify-between'>
                        <div className='mr-12'><Input label="Company Name" linkedName="companyName" inputType="text"/></div>
                        <div className='mr-12'><Input label="Primary website domain" linkedName="website" inputType="text"/></div>
                    </div>
                    <Input className="lg:w-[34%] mr-12 lg:mr-0" label="Billing email" linkedName="billingEmail" inputType="email"/>
                </div>
                
                
                <button className='mt-6 px-4 py-1 rounded-lg border border-gray-300 text-gray-600'>Save</button>
                
                
            
        </div>
    </div>
  )
}
