import React from "react";
import LogoBlack from "assets/img/logo_black.png";
import { Link, useLocation } from "react-router-dom";
import ActiveIcon from "assets/img/icons/Active.svg";
import SettingsIcon from "assets/img/icons/Settings.svg";
import { classNames } from "utils/helpers";
import { useUserState } from "contexts/user_context";
import { ReactComponent as Menu } from "assets/img/icons/Menu.svg";
import  User  from "assets/img/icons/UserOrange.svg";
import useOpenable from "hooks/useOpenable";
import Drawer from "components/Drawer";
import DropdownMenu from "components/DropdownMenu";
import {DashboardIcon, TemplatesIcon, HistoryIcon, AddIcon, FavoritesIcon, UserIcon } from 'utils/iconsSVG'


export default function SideBar() {
  const location = useLocation();
  const [open, toggleOpen, handleClose] = useOpenable();
  const { user } = useUserState();
  const sideBarItems = [
    {
      icon:<DashboardIcon fill="#A8A8A8" className="ml-6 mr-1"/>,
      iconActive:<DashboardIcon fill="#f19e38" className="ml-6 mr-1"/>,
      title: "Dashboard",
      to: "/dashboard",
    },
    {
      icon:<TemplatesIcon fill="#A8A8A8" className="ml-6 mr-1"/>,
      iconActive:<TemplatesIcon fill="#f19e38" className="ml-6 mr-1"/>,
      title: "Templates",
      to: "/templates",
    },
    {
      icon:<HistoryIcon fill="#A8A8A8" className="ml-6 mr-2"/>,
      iconActive:<HistoryIcon fill="#f19e38" className="ml-6 mr-2"/>,
      addIcon:<AddIcon fill="#A8A8A8" className="ml-2 h-4"/>,
      title: "History",
      to: "/history",
    },
    {
      icon:<FavoritesIcon fill="#A8A8A8" className="ml-5 mr-1"/>,
      iconActive:<FavoritesIcon fill="#f19e38" className="ml-5 mr-1"/>,
      title: "Favorites",
      to: "/favorites",
    }
  ];

  return (
    <div className="w-full">
      <div className="flex flex-col h-screen bg-text-primary">
	  <div
            className="flex mx-auto mt-4 lg:hidden h-6 w-6"
            onClick={() => {
              toggleOpen();
            }}
          >
            <Menu fill="#555"/>
          </div>
		
          <img className="hidden lg:flex my-5 ml-6 w-28" src={LogoBlack} alt="logo" />
        
		  <Drawer isOpen={open} handleClose={handleClose}>
        <div className="mt-12 flex lg:hidden flex-col w-full h-[93vh] lg:h-full">
          <ul className={classNames("flex w-full",open ? " flex-col" : "" )}>
            {sideBarItems.map((e, index) => {
              const isSelected = location.pathname === e.to;
              return (
                <li
                  key={index}
                  className={classNames(
                    "flex flex-row items-center mb-5 ",
                    isSelected ? "border-l-secondary border-l-4" : "ml-1"
                  )}
                >
                  {isSelected ? e.iconActive : e.icon }
                  <Link
                    className={classNames(
                      "px-8 font-inter ",
                      isSelected ? "text-secondary" : "text-gray-400"
                    )}
                    to={e.to}
                  >
                    {e.title}
                  </Link>
                </li>
              );
            })}
          </ul>
		  <div className="flex lg:hidden mt-auto h-16 border-t border-t-text-secondary">
          <div className="flex w-full items-center justify-center">
          <DropdownMenu>
              <>
            <div className="ml-1">
              <UserIcon fill={"#f19e38"} className={"h-7"}/>
            </div>
            <h2 className="font-inter ml-3 text-[#313132]">
              {user?.firstName} {user?.lastName}
            </h2>
            </>
            </DropdownMenu>
            {/* <Link className="flex items-end ml-8" to={"/settings/workspace"}>
              <img className="w-4" src={SettingsIcon} alt="" />
            </Link> */}
          </div>
        </div>
        </div>
		</Drawer>
        <div className="mt-12 hidden lg:flex lg:flex-col">
          <ul>
            {sideBarItems.map((e, index) => {
              const isSelected = location.pathname === e.to;
              return (
                <li
                  key={index}
                  className={classNames(
                    "flex flex-row items-center mb-5 ",
                    isSelected ? "border-l-secondary border-l-4" : "ml-1"
                  )}
                >
                  {isSelected ? e.iconActive : e.icon}
                  <Link
                    className={classNames(
                      "px-8 font-inter ",
                      isSelected ? "text-secondary" : "text-gray-400"
                    )}
                    to={e.to}
                  >
                    {e.title}
                  </Link>
                  {e.addIcon ? e.addIcon : null}
                </li>
              );
            })}
          </ul>
        </div>
        <div className="hidden lg:flex mt-auto h-16 border-t border-t-gray-300 w-full justify-center">
          <div className="flex items-center justify-center w-full">
            <DropdownMenu>
              <>
            <div className="ml-1">
              <UserIcon fill={"#f19e38"} className={"h-7"}/>
            </div>
            <h2 className="font-inter ml-3 text-[#313132]">
              {user?.firstName} {user?.lastName}
            </h2>
            </>
            </DropdownMenu>
            {/* <Link className="flex items-end ml-8" to={"/settings/workspace"}>
              <img className="w-4" src={SettingsIcon} alt="" />
            </Link> */}
          </div>
        </div>
      </div>
    </div>
  );
}
