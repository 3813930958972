import { ReactNode } from "react";
import { classNames } from "utils/helpers";
// import { ReactComponent as Arrow } from "assets/img/icons/Arrow 2.svg";

function Button({
  onClick,
  background,
  borders,
  textColor,
  type = "button",
  children,
  height,
  padding,
}: {
  onClick?: ((e: React.MouseEvent<HTMLButtonElement>) => Promise<void>) | (() => void);
  background: string;
  borders?:string;
  textColor:string;
  height?:string;
  padding?:string;
  type?: "button" | "submit" | "reset";
  children: ReactNode;
}) {
  return (
      <button
        className={classNames("flex justify-center items-center py-1 lg:py-3 px-2 rounded text-xs lg:text-sm font-roboto font-semibold w-full",background,textColor,height ? height : "",borders ? borders : "",padding ? padding : "py-1 lg:py-3 px-2")}
        type={type}
        onClick={onClick}
      >
        {children}
      </button>
  );
}

export default Button;
