import { ReactNode } from "react";

export default function Drawer({
	isOpen,
	handleClose,
	children,
}: {
	isOpen: boolean;
	handleClose: () => void;
	children: ReactNode;
}) {
	return (
		<main
			className={
				" fixed overflow-hidden z-10 bg-gray-900 bg-opacity-25 inset-0 transform ease-in-out " +
				(isOpen
					? " transition-opacity opacity-100 duration-500 translate-x-0  "
					: " transition-all delay-500 opacity-0 translate-x-full")
			}
		>
			<section
				className={
					" w-[80vw] max-w-lg right-0 absolute bg-white h-full shadow-xl delay-400 duration-500 ease-in-out transition-all transform  " +
					(isOpen ? " translate-x-0 " : " translate-x-full ")
				}
			>
				<article className="relative w-full max-w-lg flex flex-col space-y-2 px-4 overflow-y-scroll h-full">
					<div className="flex flex-column justify-start items-center my-2 mx-2">
						{children}
					</div>
				</article>
			</section>
			<section
				className=" w-screen h-full cursor-pointer "
				onClick={() => {
					handleClose();
				}}
			></section>
		</main>
	);
}
