import React, { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { classNames } from "utils/helpers";
import { FavoritesIcon } from "utils/iconsSVG";

export default function Card({
  title,
  description,
  link,
  width,
  icon,
}: {
  title?: string;
  description?: string;
  link?: string;
  width?: string;
  icon?: ReactElement;
}) {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => {
        navigate(link ? link : "");
      }}
      className={classNames(
        "my-8 lg:mb-0 h-80 lg:h-[280px] rounded-lg py-4 shadow-xl mx-6 cursor-pointer",
        width ? width : ""
      )}
    >
      {title ? (
        <>
          <div className="flex justify-between items-center px-4 h-[10%]">
            {icon}
            <FavoritesIcon fill={"#555"} className={""} />
          </div>
          <div className="px-6 py-4 overflow-hidden text-ellipsis h-[80%]">
            <div className="font-bold text-xl mb-2">{title}</div>
            <p className="text-gray-500 text-base">{description}</p>
          </div>
        </>
      ) : ""}
    </div>
  );
}
