import React, { useState } from "react";
import { ReactComponent as Plus } from "assets/img/icons/Plus.svg";
import { classNames } from "utils/helpers";

interface Question {
  id: number;
  title: string;
  content: string[];
}

export default function Questions() {
  //eslint-disable-next-line
  const [questions, setQuestions] = useState<Question[]>([
    {
      id: 0,
      title: "1. Does it cost anything to add my team members to my account?",
      content: [
        "Nope! You can add as many team members as you want to Amzhub at no additional cost.",
      ],
    },
    {
      id: 1,
      title: "2. Is the content from Amzhub original?",
      content: [
        "The content that we generate is original content that doesn't repeat itself and passes plagiarism tests with 99.99% original content that is free and clear for publication.",
      ],
    },
    {
      id: 2,
      title: "3. What's the Amzhub refund policy?",
      content: [
        "We offer a -7 risk free refund policy. If you are not satisfied with your outcomes just email hello@amzhub.com we’ll instantly refund 100% of your money. No questions asked.",
      ],
    },
    {
      id: 3,
      title: "4. What happens if I hit my plan's word limit?",
      content: [
        "If you meet your plan's limit in 30 days, you have two options:",
        "Option 1: Upgrade to a higher monthly limit",
        "Scale up your plan as your content needs grow. The price per word decreases as you scale up. Unused words do not roll over to the next month.",
        "Option 2: Wait until your credits renew at the start of your next billing cycle.",
      ],
    },
    {
      id: 4,
      title: "5. What are the available payment methods?",
      content: [
        "Amzhub supports all major credit cards and debit cards. We does not accept Paypal, prepaid cards, or other cash apps at this time.",
      ],
    },
  ]);

  const [selected, setSelected] = useState<number | null>(null);

  const handleOpenQuestions = (index: number) => {
    if (selected === index) {
      return setSelected(null);
    }
    setSelected(index);
  };

  return (
    <>
      <div className="w-full px-2 lg:px-20">
        <div className="flex flex-col mb-4">
          <h2 className="font-roboto font-semibold text-white">
            Questions? Answers.
          </h2>
          <p className="font-roboto text-sm  text-white">
            Don’t see your question below? Email us.
          </p>
        </div>
        {questions.map((el, index) => {
          return (
            <div
              key={index}
              className={classNames(
                "flex items-center bg-brown w-full rounded-lg mb-4 py-1",
                selected === index ? "h-fit" : "h-13"
              )}
            >
              <div className="flex flex-col px-6">
                <span className="text-white ml-4 font-roboto my-2 font-bold">
                  {el.title}
                </span>
                {selected === index ? (
                  <>
                    {el.content.map((el, i) => {
                      return (
                        <span
                          key={i}
                          className="text-gray-300 ml-4 font-roboto text-sm mb-4"
                        >
                          {el}
                        </span>
                      );
                    })}
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="h-8 ml-auto mr-3 cursor-pointer">
                <Plus
                  onClick={() => {
                    handleOpenQuestions(index);
                  }}
                  className="h-full"
                />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
