import { classNames } from "utils/helpers"

export default function Input({
  linkedName,
  label,
  value,
  handleChange,
  inputType,
  className
}: {
  linkedName: string;
  label: string;
  value?: string;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  inputType: string;
  className?:string
}) {
  return (
    <div className={classNames("mb-3",className ? className : "")}>
      <label
        className="block text-gray-700 text-sm font-bold mb-2 "
        htmlFor={linkedName}
      >
        {label}
      </label>
      <input
        value={value}
        onChange={handleChange}
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        name={linkedName}
        type={inputType}
      />
    </div>
  );
}
