import { Result } from "models/core/Result";
import User from "models/User";
import Firebase from "utils/firebase";

export default class ProfileServices {
	private firebase: Firebase;

	constructor(firebase: Firebase) {
		this.firebase = firebase;
	}

	getProfile = async (uid: string): Promise<Result<User>> => {
		try {
			const data = await this.firebase.getDocInCollection("users", uid);
			return Result.rigth<User>(
				new User({
					id: uid,
					email: data?.email,
					firstName: data?.firstName,
					lastName: data?.lastName,
				})
			);
		} catch (error) {
			return Result.left("Ocurrio un error");
		}
	};
}
