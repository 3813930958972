import Input from "components/Input";
import React from "react";

export default function Personal() {
  return (
    <div className="w-screen flex flex-col items-center">
      <div className="mx-4 lg:ml-16 lg:mr-0 mt-10 p-4 lg:p-10 overflow-hidden shadow-xl lg:w-2/3 rounded-xl">
        <div>
          <h2 className="font-inter text-xl font-semibold text-gray-600">Personal Information</h2>
          <p className="font-light text-gray-500">Tell us about yourself</p>

          <div className="flex flex-col lg:flex-row mt-4 justify-between">
              <div className="mr-12">
                <Input
                  label="First Name"
                  linkedName="firstName"
                  inputType="text"
                />
              </div>
              <div className="mr-12">
                <Input
                  label="Last Name"
                  linkedName="lastName"
                  inputType="text"
                />
            </div>
          </div>
          <div className="w-52">
            <button className="mt-6 px-4 py-1 rounded-lg border border-gray-300 text-gray-600">Save</button>
          </div>
        </div>
      </div>

      <div className="mx-4 lg:ml-16 lg:mr-0 mt-10 p-4 lg:p-10 overflow-hidden shadow-xl lg:w-2/3 rounded-xl mb-8">
        <div>
          <h2 className="font-inter text-xl font-semibold text-gray-600">Authentication</h2>
          <p className="font-light text-gray-500">Add or update your password</p>

          <div className="flex flex-row mt-4 items-center">
            
              <div className="mr-12">
                <Input
                  label="New Password"
                  linkedName="newPassword"
                  inputType="password"
                />
              </div>
          </div>
          <div className="w-52">
            <button className="mt-6 px-4 py-1 rounded-lg border border-gray-300 text-gray-600">Update Password</button>
          </div>
        </div>
      </div>
    </div>
  );
}
