import {
	useState,
	useEffect,
	useRef,
	ReactNode,
	MutableRefObject,
	ChangeEvent,
} from "react";
import { classNames } from "utils/helpers";

type TextInputProps = {
	name: string;
	value: string;
	onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
	className?: string;
	placeholder?: string;
	validate?: (value: string) => string;
	multiline?: boolean;
	type?: string;
	suffixIcon?: ReactNode;
};

export default function TextInput({
	name,
	value,
	onChange,
	className,
	placeholder = "",
	validate,
	multiline = false,
	type = "text",
	suffixIcon,
}: TextInputProps) {
	const [error, setError] = useState("");
	const [startTyping, setStartTyping] = useState(false);
	const inputRef: MutableRefObject<any> = useRef();

	useEffect(() => {
		if (validate && !startTyping) {
			setError(validate(value));
		} // eslint-disable-next-line
	}, [value]);

	const inputStyles = classNames(
		"w-full p-3 text-base focus:outline-none border focus:ring-1 rounded-md text-gray-600",
		!!error && startTyping
			? "border-red-500 focus:ring-red-500"
			: "border-gray-400 focus:ring-primary"
	);

	const Text = (
		<div className="relative">
			{suffixIcon && (
				<div className="absolute inset-y-0 right-4 flex items-center justify-end pl-3">
					{suffixIcon}
				</div>
			)}
			<input
				name={name}
				value={value}
				ref={inputRef}
				onChange={(e) => {
					const value = e.target.value;
					inputRef.current?.classList.remove("border-red-600");
					onChange(e);
					if (value && !startTyping) {
						setStartTyping(true);
					}
					if (validate) {
						setError(validate(value));
					}
				}}
				className={classNames(className || "", inputStyles)}
				type={type}
				placeholder={placeholder}
			/>
		</div>
	);

	const TextArea = (
		<textarea
			name={name}
			value={value}
			ref={inputRef}
			onChange={(e) => {
				const value = e.target.value;
				inputRef.current?.classList.remove("border-red-600");
				onChange(e);
				if (value && !startTyping) {
					setStartTyping(true);
				}
				if (validate) {
					setError(validate(value));
				}
			}}
			className={classNames(inputStyles, className || "")}
			placeholder={placeholder}
			maxLength={400}
		/>
	);

	return (
		<div className="flex flex-col">
			<div>{!multiline ? Text : TextArea}</div>
			{error && (
				<div
					id={`error-message-${name}`}
					className={classNames(
						"text-xs text-red-500 ml-2 mt-1",
						value === "" && !startTyping ? "invisible absolute" : ""
					)}
				>
					{error}
				</div>
			)}
		</div>
	);
}
