import React from "react";
import { CopyIcon,FavoriteIcon } from "utils/iconsSVG";


export default function Card({text,date,setFavorite,id,favorite}:{text:string,date:string,setFavorite:(id:string) =>Promise<void>,id:string | undefined,favorite:boolean}) {

  
  return (
    <div className="my-4 border-b border-gray-300 py-4">
      <div className="flex items-center">
        <span className="ml-4 mr-4 text-disabled font-inter text-xs font-extralight">{date}</span>
        <div className="flex items-center ml-auto mr-5">
        {id ? <div onClick={()=>setFavorite(id)}><FavoriteIcon fill={favorite?"#F00":"#555"} className="h-5 ml-1"/></div> : <div ><FavoriteIcon fill={favorite?"#F00":"#555"} className="h-5 ml-1"/></div>}
        <CopyIcon fill="#555" className="h-4 flex ml-4" />
        </div>
      </div>
      <p className="mx-6 my-6 font-inter max-h-80 text-ellipsis overflow-hidden">{text}</p>
    </div>
  );
}
