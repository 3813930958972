import React, { useState } from 'react'
import { classNames } from 'utils/helpers';
import OutputIcon from "assets/img/icons/Idea.svg"
import { HistoryIcon } from 'utils/iconsSVG';
import Card from '../Card';
import useOutput from 'hooks/useOutput';
import { useUserState } from 'contexts/user_context';

interface Output {
  id?: string;
  content:string;
  date: Date;
  favorite:boolean;
  relativeDate: string;
}
  

export default function OutputPanel({newOutputs,historyOutputs,setHistoryOutputs}:{newOutputs:Output[];historyOutputs:Output[];setHistoryOutputs:React.Dispatch<React.SetStateAction<Output[]>>}) {

  const [tabs, setTabs] = useState(true);
  const { user } = useUserState();
  let uid = user?.id as string
  const {changeFavorites} = useOutput()

  const setFavorite = async (id:string) =>{
    
   const copy =  historyOutputs.map(el =>{
        if(el.id === id){
          let docId = el.id
        changeFavorites({uid:uid,docId:docId,data:{favorite:!el.favorite}})
        return {
          ...el,
          favorite:!el.favorite
        }
        }
        return el
      })
      setHistoryOutputs(copy)
  }
  
  return (
    <div className="lg:w-[50%] border-t border-r border-[#d9d9d9] h-[72vh] overflow-y-auto">
          <div className="flex h-10">
            <div
              onClick={() => {
                setTabs(true);
              }}
              className={classNames(
                "flex items-center justify-center w-[50%] lg:w-[30%] h-full  border-b border-[#d9d9d9] cursor-pointer",
                tabs ? "bg-[#d9d9d9]" : "bg-white"
              )}
            >
              <img className="h-5 mr-1" src={OutputIcon} alt="" />
              <span className="font-inter text-gray-600 text-sm">New outputs</span>
            </div>
            <div
              onClick={() => {
                setTabs(false);
              }}
              className={classNames(
                "flex items-center w-[50%] lg:w-[70%] h-full border-l border-b  border-[#d9d9d9] cursor-pointer",
                tabs ? "bg-white" : "bg-[#d9d9d9]"
              )}
            >
              <HistoryIcon fill={"#999888"} className={"ml-4 h-4"}/>
              <span className="ml-1 font-inter text-gray-600 text-sm">History</span>
            </div>
          </div>
          <div className="">
            {tabs ? (
              <div>
                {newOutputs
                  ? newOutputs.map((el, i) => {
                      return <Card key={i} text={el.content} date={el.relativeDate} setFavorite={setFavorite} id={el.id} favorite={el.favorite} />;
                    })
                  : null}
              </div>
            ) : (
              <div>
                {historyOutputs ? historyOutputs.map((el, i) =>{
                  return <Card key={i} text={el.content} date={el.relativeDate} setFavorite={setFavorite} id={el.id} favorite={el.favorite} />;
                }): null}
              </div>
            )}
          </div>
        </div>
  )
}
