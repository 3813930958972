import { initializeApp } from "firebase/app";
import {
	addDoc,
	collection,
	doc,
	Firestore,
	getDoc,
	getDocs,
	getFirestore,
	orderBy,
	query,
	setDoc,
	updateDoc,
	where,
} from "firebase/firestore";
import { Analytics, getAnalytics } from "firebase/analytics";
import {
	Auth,
	getAuth,
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
	signOut,
	onAuthStateChanged,
	User,
	NextOrObserver,
	GoogleAuthProvider,
	signInWithPopup,
	sendPasswordResetEmail,
} from "firebase/auth";

const firebaseConfig = {
	apiKey: "AIzaSyA0j30l-vluD-J_OZ3-6nwsg0pYBwz0lmk",
	authDomain: "amzhub-8b888.firebaseapp.com",
	projectId: "amzhub-8b888",
	storageBucket: "amzhub-8b888.appspot.com",
	messagingSenderId: "152782148215",
	appId: "1:152782148215:web:2f5fc89ae809654aa46c72",
	measurementId: "G-YQ01CQEBLM",
};


class Firebase {
	public auth: Auth;
	public analytics: Analytics;
	public firestore: Firestore;
	public provider: GoogleAuthProvider;
	// public functions: firebase.functions.Functions;

	public constructor() {
		const app = initializeApp(firebaseConfig);
		this.analytics = getAnalytics(app);
		this.firestore = getFirestore(app);
		this.auth = getAuth(app);
		this.provider = new GoogleAuthProvider();
		// this.functions = firebase.functions();
		// if (Config.isInDev) {
		// 	this.functions.useEmulator("localhost", 5001);
		// 	this.auth.useEmulator("http://localhost:9099");
		// 	this.storage.useEmulator("localhost", 9190);
		// 	this.firestore.settings({
		// 		host: "localhost:8080",
		// 		ssl: false,
		// 	});
		// }
	}

	public onAuthChange = (nextOrObserver: NextOrObserver<User>) => {
		return onAuthStateChanged(this.auth, nextOrObserver);
	};

	public signInWithEmailAndPassword = async (
		email: string,
		password: string
	) => {
		const userCredential = await signInWithEmailAndPassword(
			this.auth,
			email,
			password
		);
		// Signed in
		const user = userCredential.user;
		return user;
	};

	public signInWithGoogle = async () => {
		const userCredential = await signInWithPopup(
			this.auth,
			this.provider
		);
		// Signed in with Google
		const user = userCredential.user;
		return user;
	}

	public signUpWithEmailAndPassword = async (
		email: string,
		password: string
	) => {
		const userCredential = await createUserWithEmailAndPassword(
			this.auth,
			email,
			password
		);
		// Signed in
		const user = userCredential.user;
		return user;
	};

	public ForgotPasswordResetEmail = async (email: string) =>{
		const userCredential = await sendPasswordResetEmail(this.auth,email);

		console.log("User Credencial",userCredential)
		return userCredential
	}

	

	public logOut = () => {
		return signOut(this.auth);
	};

	public addDocToCollection = (collectionName: string, data: any) => {
		return addDoc(collection(this.firestore, collectionName), data);
	};

	public addDocToSubCollection = (collectionName: string,docCollectionId:string,subCollectionName: string,data:any) => {
		 return addDoc(collection(this.firestore,collectionName,docCollectionId,subCollectionName),data)
	}

	public updateDocInCollection = (
		collectionName: string,
		docId: string,
		data: any
	) => {
		return updateDoc(doc(this.firestore, collectionName, docId), data);
	};

	public updateDocInSubCollection = async (collectionName: string,docCollectionId:string,subCollectionName: string,docId:string,data:any) =>{
		return await updateDoc(doc(this.firestore, collectionName,docCollectionId,subCollectionName,docId), data)
	}

	public setDocInCollection = (
		collectionName: string,
		docId: string,
		data: any
	) => {
		return setDoc(doc(this.firestore, collectionName, docId), data);
	};

	public getDocInCollection = async (collectionName: string, docId: string) => {
		const docSnapshot = await getDoc(
			doc(this.firestore, collectionName, docId)
		);
		const data = docSnapshot.data();
		return data;
	};
	public getDocsInSubCollection = async (collectionName: string,docCollectionId:string,subCollectionName: string,orderByField:string) => {
		const q = query(collection(this.firestore,collectionName,docCollectionId,subCollectionName ), orderBy(orderByField, "desc"));
		const docSnapshot = await getDocs(
			q)
		;
		let data:any[] = [] 
		docSnapshot.forEach((doc) => {
			data.push({id:doc.id,...doc.data()}) 
		})
		
		return data;
	};

	public getDocsInSubCollectionByCond = async (collectionName: string,docCollectionId:string,subCollectionName: string,orderByField:string,cond1: string,cond2:any)=> {
		
		const q = query(collection(this.firestore,collectionName,docCollectionId,subCollectionName), where(cond1,"==",cond2),orderBy(orderByField,"desc"));

		const querySnapshot = await getDocs(q);

		
		let data:any[] = [] 
		querySnapshot.forEach((doc) => {
			
			data.push({id:doc.id,...doc.data()}) 
		})
		
		return data;
	}

	
}

export default Firebase;
