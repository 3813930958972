import { Link } from "react-router-dom";
import Logo from "assets/img/logo.svg";
import { ReactComponent as Menu } from "assets/img/icons/Menu.svg";
import  MenuHam  from "assets/img/icons/Menu Hamburger.svg";
import { ReactComponent as XMenu } from "assets/img/icons/x.svg";
import { classNames } from "utils/helpers";
import useOpenable from "hooks/useOpenable";
import Drawer from "components/Drawer";
import Button from "components/Button";

function NavBar() {
	const [open, toggleOpen, handleClose] = useOpenable();

	return (
		<nav className="flex pt-8 items-center">
			<div className="ml-6 w-[30%] lg:w-[20%] lg:ml-20">
				<Link to="/">
					<img src={Logo} className="w-[100%] lg:w-34 lg:w-32" alt="logo" />
				</Link>
			</div>
			{/* <img onClick={()=>{setOpen(!open)}} className="ml-auto mr-6 lg:hidden h-7" src={open ? X : Menu} alt="" /> */}
			<div className="h-6 ml-auto lg:hidden flex"><Button padding="py-2" height="h-7" borders="rounded-sm" background={"bg-secondary"} textColor={"text-black"}><Link to="/sign-up">Sign Up</Link> </Button></div>
			<div
				className="h-6  mr-6 lg:hidden flex"
				onClick={() => {
					toggleOpen();
				}}
			>
				<img className="h-6 w-6 ml-4" src={MenuHam} alt="" />
			</div>
			<Drawer isOpen={open} handleClose={handleClose}>
				<div className="w-full lg:hidden">
				<div className="h-6 w-6 ml-auto -m-2 mt-8 lg:hidden" onClick={() => {toggleOpen();}}><XMenu fill="#000"/></div>
					<ul className={classNames("flex w-full",open ? " flex-col" : "" )}>
						<li className="mr-10 mt-4 text-text-secondary">
							<Link to="/" className="font-roboto text-xl">
								Home
							</Link>
						</li>
						<li className="mr-10 mt-2 text-text-secondary">
							<Link to="/pricing" className="font-roboto text-xl">
								Pricing
							</Link>
						</li>
						<li className="mr-10 mt-2 text-text-secondary">
							<Link to="/about" className="font-roboto text-xl">
								About
							</Link>
						</li>
					</ul>
					<ul
						className={classNames(
							"flex lg:mr-20",
							open ? "flex-col bg-white w-full" : ""
						)}
					>
						<li className="mr-7 mt-2 text-text-secondary">
							<Link to="/sign-in" className="font-roboto text-xl">Log in</Link>
						</li>
						<li className="text-secondary mt-2">
							<Link to="/sign-up" className="font-roboto text-xl">Sign up</Link>
						</li>
					</ul>
				</div>
			</Drawer>
			<div className={classNames("hidden lg:flex lg:justify-between lg:w-[80%] ml-6" )}>
			<ul className={classNames("flex ", open ? "flex-col bg-white w-full" : "")}>
          <li className="mr-10 text-text-secondary">
            <Link to="/" className="font-roboto">
              Home
            </Link>
          </li>
          <li className="mr-10 text-text-secondary">
            <Link to="/pricing" className="font-roboto">
              Pricing
            </Link>
          </li>
          <li className="mr-10 text-text-secondary">
            <Link to="/about" className="font-roboto">
              About
            </Link>
          </li>
        </ul>

        <ul className={classNames("flex lg:mr-20", open ? "flex-col bg-white w-full" : "")}>
          <li className="mr-7 text-text-secondary font-roboto">
            <Link to="/sign-in">Log in</Link>
          </li>
          <li className="text-secondary font-roboto">
            <Link to="/sign-up">Sign up</Link>
          </li>
        </ul>
			</div>
		</nav>
	);
}

export default NavBar;
