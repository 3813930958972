import Button from "components/Button";
import {ReactComponent as Arrow} from 'assets/img/icons/Arrow 4.svg'


export default function CardPlan({
  title,
  subtitle,
  priceXMonth,
  priceXYear,
  features,
  mostPopular,
  selected,
}: {
  title: string;
  subtitle: string;
  priceXMonth: string[];
  priceXYear: string[];
  features: string[];
  mostPopular: boolean;
  selected:number;
}) {
  
  return (
    <div className="flex flex-col h-full py-4 shadow-lg">
      {mostPopular ? <div className="lg:absolute lg:z-[3] -mt-10 lg:mt-0 self-end mr-6 lg:-top-5 border-2 border-secondary bg-primary px-1 py-1 "><span className="text-secondary text-sm">Most Popular</span></div> : null}
      <div className="flex flex-col items-center mt-4 px-10 mb-5">
      <h2 className="text-2xl font-semibold text-primary">{title}</h2>
      <p className="text-xs">{subtitle}</p>
      <hr className="border-[#757575] w-full mb-8 mt-4" />
      <h2 className="text-xl font-semibold mb-1 text-primary">{selected === 1 ? priceXMonth[0] : priceXYear[1]}</h2>
      <p className="text-xs mb-4 text-[#121824]">{selected === 2 ?  priceXMonth[1] : priceXYear[0]}</p>
      <Button background={"bg-transparent"} borders={"border border-black"} textColor={"text-primary"} onClick={()=>{}}><div className="flex items-center"><span className="font-roboto mr-3">Start Now</span><Arrow/></div></Button>
      <p className="text-xs mt-1 text-[#121824]">7-day 100% money back guarantee.</p>
      </div>  
     
      <div className="flex flex-col pl-10">
      <p className="font-semibold mb-2 font-roboto">Quick overview:</p>
      <div className="">
      {features.map((feature,index) =>{
          return <div className="flex items-center -ml-4 mb-1"><Arrow/><p className="text-sm font-roboto ml-1" key={index}>{feature}</p></div>
      })}
      </div>
      </div>
    </div>
  );
}
