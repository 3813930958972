import NavBar from 'pages/Home/components/NavBar'
import React from 'react'
import AboutImg from 'assets/img/About.png'
import Questions from 'pages/Home/components/Questions'
import Footer from 'pages/Home/components/Footer'

export default function About() {
  return (
    <div className="bg-primary w-full overflow-x-hidden h-full">
        <NavBar/>
        <div className="flex w-full h-screen">
            <div className="w-[80%] lg:w-[50%]">
                <img src={AboutImg} alt="" />
            </div>
            <div className="flex flex-col justify-center w-[80%] lg:w-[50%] ">
            <h1 className="text-white text-4xl  text-center lg:text-start mb-6 font-roboto font-extrabold ml-[15%] -mt-[15%]">
            We are <br/> <span className="text-secondary text-6xl">Amzhub</span>{" "}
            </h1>
            <p className="text-text-primary text-xl text-center lg:text-start mb-6 font-roboto w-[70%] ml-[15%] ">Our mission is to provide powerful AI generated copy and resources to help entrepreneurs and brands grow successful businesses on Amazon.</p>
            </div>
        </div>

        <Questions/>
        <Footer/>
    </div>
  )
}
