import Layout from "components/Layout";
import Loading from "components/Loading";
import { PrivateRoute, PublicRoute } from "components/Routes";
import { setUser, useUserDispatch } from "contexts/user_context";
import useAuth from "hooks/useAuth";
import useProfile from "hooks/useProfile";
import ForgotPassword from "pages/Auth/ForgotPassword";
import SignIn from "pages/Auth/LogIn";
import SignUp from "pages/Auth/SignUp";
import DashBoard from "pages/Dashboard";
import DescriptionGenerator from "pages/Templates/DescriptionGenerator";
import Favorites from "pages/Favorites";
import Home from "pages/Home";
import Pricing from "pages/Pricing";
import Settings from "pages/Settings";
import Billing from "pages/Settings/Billing";
import Personal from "pages/Settings/Personal";
import Workspace from "pages/Settings/Workspace";
import Templates from "pages/Templates";
import History from "pages/History";
import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import ReviewGenerator from "pages/Templates/ReviewGenerator";
import ExistingCopyGenerator from "pages/Templates/ExistingCopyGenerator";
import KeyWordGenerator from "pages/Templates/KeyWordsGenerator";
import About from "pages/About";

export default function App() {
	const { onAuthChange } = useAuth();
	const { getProfile } = useProfile();
	const userDispatch = useUserDispatch();

	const [firebaseInitailized, setFirebaseInitailized] = useState(false);

	useEffect(() => {
		onAuthChange(async function (user) {
			if (user) {
				// await userActions.checkAuthenticated(userDispatch);
				const result = await getProfile(user.uid);
				if (result.isRigth) {
					setUser(userDispatch, result.getValue());
				}
			}
			setFirebaseInitailized(true);
		}); // eslint-disable-next-line
	}, []);

	return firebaseInitailized ? (
		<Routes>
			<Route path="/" element={<PublicRoute Component={Home} />} />
			<Route path="/sign-in" element={<PublicRoute Component={SignIn} />} />
			<Route path="/sign-up" element={<PublicRoute Component={SignUp} />} />
			<Route path="/forgot-password" element={<PublicRoute Component={ForgotPassword} />} />
			<Route path="/pricing" element={<PublicRoute Component={Pricing} />} />
			<Route path="/about" element={<PublicRoute Component={About} />} />
			<Route path="/" element={<PrivateRoute Component={Layout} />}>
				<Route
					path="/dashboard"
					element={<PrivateRoute Component={DashBoard} />}
				/>
				<Route
					path="/templates"
					element={<PrivateRoute Component={Templates} />}
				/>
				<Route
					path="/templates/descriptionGenerator"
					element={<PrivateRoute Component={DescriptionGenerator} />}
				/>
				<Route
					path="/templates/reviewGenerator"
					element={<PrivateRoute Component={ReviewGenerator} />}
				/>
				<Route
					path="/templates/existingCopyGenerator"
					element={<PrivateRoute Component={ExistingCopyGenerator} />}
				/>
				<Route
					path="/templates/keyWordGenerator"
					element={<PrivateRoute Component={KeyWordGenerator} />}
				/>
				<Route
					path="/favorites"
					element={<PrivateRoute Component={Favorites} />}
				/>
				<Route path="/history" element={<PrivateRoute Component={History} />} />
				<Route path="/settings" element={<PrivateRoute Component={Settings} />}>
					<Route
						path="/settings/workspace"
						element={<PrivateRoute Component={Workspace} />}
					/>
					<Route
						path="/settings/personal"
						element={<PrivateRoute Component={Personal} />}
					/>
					<Route
						path="/settings/billing"
						element={<PrivateRoute Component={Billing} />}
					/>
				</Route>
			</Route>
		</Routes>
	) : (
		// <div>Loading...</div>
		<Loading/>
		
	);
}
