import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { classNames } from "utils/helpers";

export default function Tabs() {
  const location = useLocation();
  const [tabs, setTabs] = useState([
    {
      link: "/settings/workspace",
      text: "Workspace",
    },
    {
      link: "/settings/personal",
      text: "Personal",
    },
    {
      link: "/settings/billing",
      text: "Billing",
    },
  ]);

  return (
    <div className="ml-12 text-sm font-medium text-center text-gray-500  border-gray-200 dark:text-gray-400 dark:border-gray-700">
      <ul className="flex flex-wrap -mb-px">
		  {tabs.map((el,i)=>{
			  const isSelected = location.pathname === el.link;
			  return <li key={i} className="mr-2">
			  <Link
				to={el.link}
				className= {classNames("inline-block p-4 rounded-t-lg   hover:text-gray-600 hover:border-gray-300",isSelected ? "border-b-2 text-gray-600 border-gray-300": "" )}
			  >
				{el.text}
			  </Link>
			</li>
		  })}
      </ul>
    </div>
  );
}
