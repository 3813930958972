import Card from 'pages/Favorites/components/CardFavorites/index'
import { useUserState } from 'contexts/user_context';
import useOutput from 'hooks/useOutput'
import React, { useEffect, useState } from 'react'
import getTime from 'utils/dateHelpers';
import { DividerArrow } from 'utils/iconsSVG';
import Spinner from 'components/Spinner';

interface Output {
  id: string;
  content:string;
  date: Date;
  favorite:boolean;
  relativeDate: string;
}

export default function Favorites() {

  const { user } = useUserState();
  let uid = user?.id as string

  const {getFavorites,changeFavorites} = useOutput()

  const [favorites,setFavorites] = useState<Output[]>();

  useEffect(() => {
    getData()
  },[])

  const getData = async () =>{
   
    const userID = user?.id as string
    const outputs = await getFavorites({uid:userID})
    const copy:Output[] = []
    outputs.map(el =>{
      copy.push({
        id: el.id,
        content: el.content,
        date:el.date,
        favorite: el.favorite,
        relativeDate:getTime(el.date) as string
      })
    })
    setFavorites(copy)
  }
  
  const setFavorite = async (id:string) =>{
    
    const copy = await favorites?.map(el =>{
         if(el.id === id){
           let docId = el.id
         changeFavorites({uid:uid,docId:docId,data:{favorite:!el.favorite}})
         return {
           ...el,
           favorite:false
         }
         }
         return el
       })
       setFavorites(copy)
       getData()
   }


  return (
    <div className=' mt-10'>
    <h2 className='text-2xl  ml-12 font-semibold font-inter flex items-center'>History <DividerArrow fill={''} className={''}/> Favorites</h2>
    
   {favorites ?  <div className="flex flex-col lg:grid lg:grid-cols-3 mt-12">
      {favorites ? favorites.map((el, index) =>{
          return <Card key={index} width="w-[80%]" content={el.content} templateType='Product Description' favorite={el.favorite} setFavorite={setFavorite} id={el.id} date={el.relativeDate}/>
      }): null}
    </div> :  <div className="flex items-center justify-center w-full h-[70vh]"><Spinner/></div>}
    </div>
  )
}
