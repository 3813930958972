import react from "react";

export const DashboardIcon = ({fill,className}: {fill: string;className: string;}) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M20 2.14286C20 0.957143 19.0429 0 17.8571 0H2.14286C0.957143 0 0 0.957143 0 2.14286V17.7714C0 19 1 20 2.22857 20H17.8571C19.0429 20 20 19.0429 20 17.8571V2.14286ZM17.8571 2.14286V7.85714H11.0714V2.14286H17.8571ZM2.14286 17.8571V2.14286H8.92857V17.8571H2.14286ZM17.8571 17.8571H11.0714V10H17.8571V17.8571Z" fill={fill}/>
    </svg>
  );};

export const TemplatesIcon = ({fill, className,}: {fill: string; className: string;}) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path fillRule="evenodd" clipRule="evenodd" d="M0 0V6.66667H20V0H0ZM17.7778 2.22222H2.22222V4.44444H17.7778V2.22222Z" fill={fill}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M0 8.88892V20H8.88889V8.88892H0ZM6.66667 11.1111H2.22222V17.7778H6.66667V11.1111Z" fill={fill}/>
      <path d="M19.9983 8.88892H11.1094V11.1111H19.9983V8.88892Z" fill={fill} />
      <path d="M11.1094 13.3333H19.9983V15.5555H11.1094V13.3333Z" fill={fill} />
      <path d="M19.9983 17.7778H11.1094V20.0001H19.9983V17.7778Z" fill={fill} />
    </svg>
  );};

export const HistoryIcon = ({fill, className,}: {fill: string; className: string;}) => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M8.5 0C13.1946 0 17 3.80545 17 8.5C17 13.1946 13.1946 17 8.5 17C3.80545 17 0 13.1946 0 8.5H1.7C1.7 12.2553 4.7447 15.3 8.5 15.3C12.2553 15.3 15.3 12.2553 15.3 8.5C15.3 4.7447 12.2553 1.7 8.5 1.7C6.1625 1.7 4.1004 2.87895 2.87725 4.675H5.1V6.375H0V1.275H1.7V3.4C3.2504 1.3345 5.71965 0 8.5 0ZM9.35 4.25V8.14725L12.1066 10.9038L10.9038 12.1066L7.65 8.85105V4.25H9.35Z" fill={fill}/>
    </svg>
  );};

export const AddIcon = ({fill,className,}: {fill: string; className: string;}) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M8.57143 8.57143V0H11.4286V8.57143H20V11.4286H11.4286V20H8.57143V11.4286H0V8.57143H8.57143Z" fill={fill}/>
    </svg>
  );};

export const FavoritesIcon = ({fill, className,}: {fill: string; className: string;}) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M12.5037 4.71769C14.9505 2.52081 18.7318 2.59373 21.0891 4.95519C23.4453 7.31769 23.5266 11.0802 21.3349 13.5344L12.5016 22.3802L3.67032 13.5344C1.47865 11.0802 1.56094 7.31144 3.91615 4.95519C6.27553 2.59685 10.0495 2.51769 12.5037 4.71769ZM19.6141 6.42706C18.0516 4.86248 15.5307 4.79894 13.8953 6.26769L12.5047 7.5156L11.113 6.26873C9.4724 4.7979 6.95678 4.86248 5.39011 6.42915C3.83803 7.98123 3.7599 10.4656 5.19011 12.1073L12.5026 19.4312L19.8151 12.1083C21.2464 10.4656 21.1682 7.98435 19.6141 6.42706Z" fill={fill} />
    </svg>
  );
};
export const FavoriteIcon = ({fill, className,}: {fill: string; className: string;}) => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
<path d="M7.50063 2.83061C8.96875 1.51249 11.2375 1.55624 12.6519 2.97311C14.0656 4.39061 14.1144 6.64811 12.7994 8.12061L7.49938 13.4281L2.20063 8.12061C0.885629 6.64811 0.935004 4.38686 2.34813 2.97311C3.76375 1.55811 6.02813 1.51061 7.50063 2.83061ZM11.7669 3.85624C10.8294 2.91749 9.31688 2.87936 8.33563 3.76061L7.50125 4.50936L6.66625 3.76124C5.68188 2.87874 4.1725 2.91749 3.2325 3.85749C2.30125 4.78874 2.25438 6.27936 3.1125 7.26436L7.5 11.6587L11.8875 7.26499C12.7463 6.27936 12.6994 4.79061 11.7669 3.85624Z" fill={fill}/>
</svg>
  );
};

export const UserIcon = ({fill,className}:{fill: string; className: string;}) => {
return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512" className={className}>
  <path d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 96c48.6 0 88 39.4 88 88s-39.4 88-88 88-88-39.4-88-88 39.4-88 88-88zm0 344c-58.7 0-111.3-26.6-146.5-68.2 18.8-35.4 55.6-59.8 98.5-59.8 2.4 0 4.8.4 7.1 1.1 13 4.2 26.6 6.9 40.9 6.9 14.3 0 28-2.7 40.9-6.9 2.3-.7 4.7-1.1 7.1-1.1 42.9 0 79.7 24.4 98.5 59.8C359.3 421.4 306.7 448 248 448z" fill={fill}/>
  </svg>)
}

export const ClearIcon = ({fill,className}:{fill: string; className: string;}) => {

 return <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
<path opacity="0.7" d="M8 9L1 1.00002M8 1L1.00001 9" stroke={fill} strokeWidth="3" strokeLinecap="round"/>
</svg>
}

export const ArrowIcon = ({fill,className}:{fill: string; className: string;}) => {
return <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
<path d="M26.0607 13.0607C26.6464 12.4749 26.6464 11.5251 26.0607 10.9393L16.5147 1.3934C15.9289 0.807613 14.9792 0.807613 14.3934 1.3934C13.8076 1.97919 13.8076 2.92893 14.3934 3.51472L22.8787 12L14.3934 20.4853C13.8076 21.0711 13.8076 22.0208 14.3934 22.6066C14.9792 23.1924 15.9289 23.1924 16.5147 22.6066L26.0607 13.0607ZM-1.31134e-07 13.5L25 13.5L25 10.5L1.31134e-07 10.5L-1.31134e-07 13.5Z" fill={fill}/>
</svg>
}

export const CopyIcon = ({fill,className}:{fill: string; className: string;}) => {

  return <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
  <path d="M9.83333 3H5.16667C4.52233 3 4 3.59695 4 4.33333V9.66667C4 10.403 4.52233 11 5.16667 11H9.83333C10.4777 11 11 10.403 11 9.66667V4.33333C11 3.59695 10.4777 3 9.83333 3Z" stroke={fill} strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M9 3.33333V2.16667C9 1.85725 8.85952 1.5605 8.60948 1.34171C8.35943 1.12292 8.02029 1 7.66667 1H2.33333C1.97971 1 1.64057 1.12292 1.39052 1.34171C1.14048 1.5605 1 1.85725 1 2.16667V6.83333C1 7.14275 1.14048 7.4395 1.39052 7.65829C1.64057 7.87708 1.97971 8 2.33333 8H3.66667" stroke={fill} strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
}

export const DividerArrow = ({fill,className}:{fill: string; className: string;}) => {
  return <svg fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className="w-8 h-6 text-gray-400"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path></svg>
}


