import React from "react";
import { Outlet } from "react-router-dom";
import Tabs from "./components/Tabs";

export default function Settings() {
	return (
		<div className="h-full">
			<h2 className="text-3xl font-inter font-semibold text-gray-600 mt-4 ml-8">Settings</h2>
			<Tabs />
			<div className="flex justify-center h-fit w-full">
				<Outlet />
			</div>
		</div>
	);
}
