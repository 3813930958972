export default function Billing() {
  return (
    <div className="w-full h-full flex flex-col items-center">
      <div className="lg:ml-16 mt-10 lg:p-5 overflow-hidden shadow-xl w-[90%] lg:w-2/3 rounded-xl mb-8 lg:h-60">
        <h2 className="text-xl font-inter text-gray-600 font-semibold ml-5 lg:ml-0">Available credits</h2>
        <hr className="w-[97%] mx-auto mt-2" />
		<div className="flex flex-col items-center lg:items-start lg:flex-row justify-between mt-16 mb-10">
             <div className="flex flex-col w-[33%] items-center border border-gray-300 rounded-lg mx-4 py-4 mb-4 lg:mb-0"><h2 className="font-inter text-gray-500 mb-1">Total credits</h2><span className="font-inter font-semibold text-xl">17,000</span></div>
             <div className="flex flex-col w-[33%] items-center border border-gray-300 rounded-lg mx-4 py-4 mb-4 lg:mb-0"><h2 className="font-inter text-gray-500 mb-1">Plan credits</h2><span className="font-inter font-semibold text-xl">20,000</span></div>
             <div className="flex flex-col w-[33%] items-center border border-gray-300 rounded-lg mx-4 py-4 mb-4 lg:mb-0"><h2 className="font-inter text-gray-500 mb-1">Bonus credits</h2><span className="font-inter font-semibold text-xl">5,000</span></div>
           </div>
      </div>
      <div className="flex flex-col lg:ml-16 mt-10 lg:p-5 overflow-hidden shadow-xl w-[90%] lg:w-2/3 rounded-xl  mb-8 lg:h-60">
        <h2 className="text-xl font-inter text-gray-600 font-semibold ml-5 lg:ml-0">Subscription</h2>
		<div className="mt-6">
		<h3 className="text-lg text-gray-500 font-inter ml-5 lg:ml-0">20,000 word credit on <span className="font-semibold">Starter</span></h3>
		<h4 className="text-gray-400 font-inter text-sm ml-5 lg:ml-0">Billing cycle renews in X days</h4>
		</div>
		<div className="flex mt-auto ml-auto w-full justify-center lg:justify-end mb-5 lg:mb-0">
		<div className="w-fit mr-5">
            <button className="mt-6 px-4 py-2 rounded-lg border border-gray-300 text-gray-600 font-inter">Edit payment details</button>
          </div>
		  <div className="w-fit">
            <button className="mt-6 px-4 py-2 rounded-lg border border-gray-300 text-gray-600 font-inter">Edit plan</button>
          </div>
		</div>
      </div>
      <div className="lg:ml-16 mt-10 lg:p-5 overflow-hidden shadow-xl w-[90%] lg:w-2/3 rounded-xl  mb-8 lg:h-32">
        <div className="flex flex-col lg:flex-row justify-between">
          <div>
            <h2 className="font-semibold text-xl font-inter text-gray-600 ml-5 lg:ml-0">Invoices</h2>
            <p className="font-extralight text-sm font-inter text-gray-500 ml-5 lg:ml-0">View your payment history</p>
          </div>
          <div className="ml-auto mr-4 mb-6 lg:mx-0 lg:mb-0">
            <button className="mt-3 px-4 py-1 rounded-lg border border-gray-300 text-gray-600 font-inter">
              View billing history
            </button>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-start mt-4 w-[90%] lg:w-2/3 mb-4 lg:ml-16 lg:mb-0">
        <h2 className="text-xl font-inter text-gray-600 mb-2 font-semibold">Cancel account</h2>
        <p className="text-sm font-extralight mb-6 font-inter text-gray-500">
          Please be aware that cancelling your account will cause you to lose
          all your saved data on your account.
        </p>
        <button className="mb-6 rounded-lg border border-gray-300 text-gray-600 font-inter px-2 py-1">
          Cancel account
        </button>
      </div>
    </div>
  );
}
