export default function getTime  (timestamp?: string | number | Date)  {
    const second = 1000
    const minute = 60 * 1000
    const hour = 60 * minute
    const day = 24 * hour
    const month = 30 * day
    const year = 12 * month
  
    // if timestand if null
  
  
    if (!timestamp) return undefined
    
    
  
    if (isNaN(new Date(timestamp).getTime())) {
      throw new Error("Invalid timestamp passed to 'getTime()'")
    }
  
    const difference = new Date().getTime() - new Date(timestamp).getTime()
    
    if (Math.floor(difference / year) > 1)
      return `${Math.floor(difference / year)}y ago`
    if (Math.floor(difference / month) > 1)
      return `${Math.floor(difference / month)}mon ago`
    if (Math.floor(difference / day) > 1)
      return `${Math.floor(difference / day)}d ago`
    if (Math.floor(difference / hour) > 1){
    
      return `${Math.floor(difference / hour)}h ago`
    }
    if (Math.floor(difference / minute) > 1 ){
      if((difference / 10000) > 60){
        return "1h ago"
      }
      return `${Math.floor(difference / minute)}m ago`
    }
      
    if (Math.floor(difference / second) > 1){
      if((difference / 1000) > 60){
        return "1m ago"
      }
      return `${Math.floor(difference / second)}s ago`
    }
      
     
    return `just now`
  }
  
  