export default class User {
	public id: string;
	public email: string;
	public firstName: string;
	public lastName: string;

	constructor({
		email,
		firstName,
		lastName,
		id,
	}: {
		firstName: string;
		lastName: string;
		email: string;
		id: string;
	}) {
		this.email = email;
		this.firstName = firstName;
		this.lastName = lastName;
		this.id = id;
	}
}
